.faq{
    margin-top: 50px;
    margin-bottom: 50px;
    &-item{
        border-top: 1px solid #d9dde8;
        padding: 24px 20px;
        &:last-child{
            border-bottom: 1px solid #d9dde8;
        }
        &.active{
            .faq-question::after{
                transform: rotate(0);
            }
        }
    }
    &-link{
        font-size: 18px;
        color: rgba(0, 56, 255, 1);
        border-bottom: 1px solid rgba(0, 56, 255, 0);
        transition: 0.3s;
        &:hover{
            color: rgba(0, 56, 255, 1);
            border-color: rgba(0, 56, 255, 1);
        }
    }
    &-question{
        font-size: 20px;
        color: #212121;
        cursor: pointer;
        position: relative;
        padding-right: 20px;
        &::after{
            content: '';
            position: absolute;
            right: 0;
            top: 6px;
            width: 15px;
            height: 15px;
            background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.34843' y1='1.56612' x2='14.4288' y2='14.6465' stroke='black'/%3E%3Cline x1='14.3628' y1='1.28251' x2='1.28248' y2='14.3629' stroke='black'/%3E%3C/svg%3E%0A");
            transform: rotate(-135deg);
            transition: 0.3s;
        }
    }
    &-anwser{
        color: #757575;
        display: none;
        padding-top: 12px;
    }
}