.home-slider{
    position: relative;
    height: 696px;
    margin-bottom: 50px!important;
    border-bottom: 1px solid #D9DDE8;
    .slick-list,.slick-track{
        height: 100%;
        }
    }
    .slick-slide{
        &:focus{
            outline: none;
        }
    }

.slide-text-box{
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    }
.slide-image-box{
    width: 1057px;
    right: 0;
    height: 696px;
    @media (min-width: 1930px) {
        width: 50%!important;
    }
    img{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        z-index: -1;
        }
    }
.slide-description{
    font-size: 18px;
    color: #757575;
    margin-top: 10px;
    }
.slide-name_short{
    font-size: 24px;
    font-family: ER;
    }
.slide-slogan{
    width: 307px;
    color:#fff;
    background:rgba(#0038FF,.7);
    bottom: 50px;
    left: 450px;
    padding: 35px 30px;
    min-height: 246px;
    .slick-dots{
        width: 100%;
        left: 0;
        bottom: 30px;
        padding-left: 30px;
        display: flex;
        li{
            width: 8px;
            height: 8px;
            background:#fff;
            border-radius:50%;
            &.slick-active{
                background: #FAFF00;
                }
            }
        }
    }
.slide-slogan__header{
    font-size: 27px;
    font-family: ER;
    line-height: 1;
    margin-bottom: 8px;
    }
.slider-bottons{
    margin-top: 60px;
    margin-bottom: 88px;
    }
.slide-btn{
    width: 165px;
}
.sb-bordered{
    width: 195px;
    margin-left: 30px;
}
.companys-products{
    color: #757575;
    align-items: center;
    font-size: 18px;
    cursor:pointer;
    }
.cp__mouse{
    margin-right: 10px;
}

/**/
@media (max-width: 1918px) {
        .slide-image-box{
            width: 55%;
        }
    }
@media (max-width: 1366px) {
        .slide-image-box{
            width: 58%;
        }
    }
@media (max-width: 1322px) {
    .slide-slogan{
        left: auto;
        right: 15px;
    }
    .slide-title{
        font-size: 35px;
    }
}
@media (max-width: 1250px) {
    .home-slider,
.slide-image-box{
    height: 496px;
}
.slider-bottons{
    margin-top: 30px;
    margin-bottom: 30px;
}
.slide-description{
    font-size: 1rem;
}
.slide-text-box{
    padding-top: 40px;
}
.slide-name_short{
    font-size: 18px;
}
.slide-title{
    font-size: 32px
}
}

@media (max-width: 1010px) {
    .slide-image-box{
        width: 50%;
    }
    .slide-text-box{
        width: 50%;
        padding-right: 10px;
    }
}

@media (max-width: 840px) {
   .slide-text-box{
    width: 100%;
    color: #fff;
   }
   .slide-title{
    font-size: 31px;
    color: #fff;
    width: 80%;
    br{
        display: none;
    }
   }
   .slide-description{
    color: #fff;
    width: 60%;
   }
   .companys-products{
    display: none;
   }
   .slide-image-box{
    width: 100%;
    height: 630px;
    &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(black, .6);
    }
   }
   .home-slider{
    height: 630px;
   }
   .slide-slogan{
    left: 15px;
    right: unset;
    bottom: 25px;
   }
   .container-slide-textcontent{
    position: relative;
    z-index: 20;
   }
   .sb-bordered {

    background: rgba(255,255,255,.1);
    backdrop-filter: blur(3px);
    color: #fff!important;
    border: 1px solid #fff;
}
}

@media (max-width: 782px) {
    .slide-image-box{
        height: 655px;
    }
    .home-slider{
    height: 655px;
   }
   .slide-description{
    width: 80%;
   }
}

@media (max-width: 575px) {
    .slide-description,.slide-title{
        width: 100%;
    }
}

@media (max-width: 440px) {
    .slide-slogan{
        width: auto;
        min-height: auto;
        padding: 0;
        background: none;
        .slick-dots{
            bottom: 0;
        }
    }
    .slide-slogan__header,.slide-slogan__desc{
        display: none;
    }
    .slider-bottons{
        flex-flow: column;
    }
    .sb-bordered{
        margin-left: 0;
        margin-top: 20px;
    }
    .slide-btn{
        width: 195px;
    }
     .home-slider,.slide-image-box{
    height: 550px;
   }

}

@media (max-width: 380px) {
    .slide-title{
        font-size: 25px;
    }
}