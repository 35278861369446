@charset "UTF-8";
@font-face {
  font-family: "ER";
  src: url("../fonts/ER.eot");
  src: url("../fonts/ElektraTextPro.eot?#iefix") format("embedded-opentype"), url("../fonts/ElektraTextPro.woff") format("woff"), url("../fonts/ElektraTextPro.ttf") format("truetype"); }

@font-face {
  font-family: "EB";
  src: url("../fonts/EB.eot");
  src: url("../fonts/ElektraTextPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ElektraTextPro-Bold.woff") format("woff"), url("../fonts/ElektraTextPro-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "RR";
  src: url("../fonts/RR.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "RB";
  src: url("../fonts/RB.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "RBI";
  src: url("../fonts/RBI.eot");
  src: url("../fonts/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-BoldItalic.woff") format("woff"), url("../fonts/Roboto-BoldItalic.ttf") format("truetype"); }

a {
  text-decoration: none !important; }
  a:focus {
    outline: none; }

.wrapper {
  max-width: 100%;
  min-width: 260px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  top: 104px;
  overflow: hidden; }
  .wrapper .content {
    flex: 1 0 auto; }
  .wrapper .footer {
    flex: 0 0 auto; }

html, body {
  height: 100%;
  font-family: RR;
  font-size: 16px;
  color: #212121; }

.container {
  padding: 0;
  width: 1320px; }
  @media (max-width: 1366px) {
    .container {
      width: 100%;
      padding: 0 15px; } }
  .container::before {
    display: none; }
  .container::after {
    display: none; }

.d-flex {
  display: flex;
  flex-wrap: wrap; }

.posrel {
  position: relative; }

.abs {
  position: absolute; }

.page-title, .product-title, .slide-title {
  font-size: 32px;
  font-family: ER;
  color: #212121; }
  @media (max-width: 750px) {
    .page-title, .product-title, .slide-title {
      font-size: 25px; } }

.product-title {
  font-size: 40px; }
  @media (max-width: 1250px) {
    .product-title {
      font-size: 32px; } }
  @media (max-width: 500px) {
    .product-title {
      font-size: 24px; } }

.slide-title {
  font-size: 45px; }

.ttu {
  text-transform: uppercase; }

.db {
  display: block; }

.bdr {
  border-radius: 50%; }

.posfix {
  position: fixed; }

.list-style-none {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0; }

.btn-blue, .btn-prod, .redButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0038FF;
  border-radius: 50px;
  color: #fff !important;
  font-size: 18px;
  height: 45px; }

.btn-prod {
  justify-content: flex-start;
  padding-left: 34px;
  padding-right: 15px; }
  .btn-prod svg {
    margin-left: 19px; }

.btn-bordered {
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid #212121;
  box-sizing: border-box;
  border-radius: 50px;
  color: #212121 !important;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px; }

/***/
.breadcrumb {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  padding-top: 26px;
  display: flex;
  flex-wrap: wrap; }
  .breadcrumb a {
    color: #757575; }
  .breadcrumb li:last-child a {
    pointer-events: none;
    cursor: default; }
  @media (max-width: 991px) {
    .breadcrumb li {
      display: flex; }
      .breadcrumb li.active {
        max-width: 138px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block; }
      .breadcrumb li a {
        max-width: 138px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block; } }

.breadcrumb > li + li:before {
  padding: 0 5px 0 10px; }
  @media (max-width: 991px) {
    .breadcrumb > li + li:before {
      content: "\2022";
      color: #212121; } }

.list-none {
  list-style-type: none;
  padding-left: 0; }

/****************************/
.preloader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

#circularG {
  position: relative;
  width: 68px;
  height: 68px;
  margin: 15px 0px 0; }

@media (max-width: 480px) {
  .preloader img {
    max-width: 200px; } }

.circularG {
  position: absolute;
  background-color: #0038FF;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  animation-name: bounce_circularG;
  -o-animation-name: bounce_circularG;
  -ms-animation-name: bounce_circularG;
  -webkit-animation-name: bounce_circularG;
  -moz-animation-name: bounce_circularG;
  animation-duration: 1.1s;
  -o-animation-duration: 1.1s;
  -ms-animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  -moz-animation-duration: 1.1s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal; }

#circularG_1 {
  left: 0;
  top: 27px;
  animation-delay: 0.41s;
  -o-animation-delay: 0.41s;
  -ms-animation-delay: 0.41s;
  -webkit-animation-delay: 0.41s;
  -moz-animation-delay: 0.41s; }

#circularG_2 {
  left: 7px;
  top: 7px;
  animation-delay: 0.55s;
  -o-animation-delay: 0.55s;
  -ms-animation-delay: 0.55s;
  -webkit-animation-delay: 0.55s;
  -moz-animation-delay: 0.55s; }

#circularG_3 {
  top: 0;
  left: 27px;
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s; }

#circularG_4 {
  right: 7px;
  top: 7px;
  animation-delay: 0.83s;
  -o-animation-delay: 0.83s;
  -ms-animation-delay: 0.83s;
  -webkit-animation-delay: 0.83s;
  -moz-animation-delay: 0.83s; }

#circularG_5 {
  right: 0;
  top: 27px;
  animation-delay: 0.97s;
  -o-animation-delay: 0.97s;
  -ms-animation-delay: 0.97s;
  -webkit-animation-delay: 0.97s;
  -moz-animation-delay: 0.97s; }

#circularG_6 {
  right: 7px;
  bottom: 7px;
  animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s; }

#circularG_7 {
  left: 27px;
  bottom: 0;
  animation-delay: 1.24s;
  -o-animation-delay: 1.24s;
  -ms-animation-delay: 1.24s;
  -webkit-animation-delay: 1.24s;
  -moz-animation-delay: 1.24s; }

#circularG_8 {
  left: 7px;
  bottom: 7px;
  animation-delay: 1.38s;
  -o-animation-delay: 1.38s;
  -ms-animation-delay: 1.38s;
  -webkit-animation-delay: 1.38s;
  -moz-animation-delay: 1.38s; }

@keyframes bounce_circularG {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0.3); } }

@-o-keyframes bounce_circularG {
  0% {
    -o-transform: scale(1); }
  100% {
    -o-transform: scale(0.3); } }

@-ms-keyframes bounce_circularG {
  0% {
    -ms-transform: scale(1); }
  100% {
    -ms-transform: scale(0.3); } }

@-webkit-keyframes bounce_circularG {
  0% {
    -webkit-transform: scale(1); }
  100% {
    -webkit-transform: scale(0.3); } }

@-moz-keyframes bounce_circularG {
  0% {
    -moz-transform: scale(1); }
  100% {
    -moz-transform: scale(0.3); } }

/* Slider */
.slick-loading .slick-list {
  background: #fff; }

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  /*    color: transparent;
    outline: none;
    background: transparent;*/ }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -83px;
  width: 60px;
  height: 60px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: ''; }

[dir='rtl'] .slick-prev:before {
  content: ''; }

.slick-next {
  right: -83px;
  width: 60px;
  height: 60px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: ''; }

[dir='rtl'] .slick-next:before {
  content: ''; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

@media (max-width: 640px) {
  .slick-dots {
    bottom: 265px; } }

@media (max-width: 400px) {
  .slick-dots {
    bottom: 265px; } }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 5px;
  padding: 0;
  background: url("../images/li_dots.png") no-repeat;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 12px;
  height: 12px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li.slick-active {
  background: url("../images/li_dots_activ.png") no-repeat; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  /*font-family: 'slick';*/
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  /*content: '•';*/
  text-align: center;
  opacity: 1;
  /*color: black;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: 1; }

@media (max-width: 1141px) {
  .slick-prev {
    left: -65px; }
  .slick-next {
    right: -65px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  /*display: block;*/ }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

header {
  width: 100%;
  height: 104px;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, #EBF1F2, #EBF1F2), #F5F3EC;
  position: fixed;
  z-index: 900; }
  header.scrolled {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2); }

.container-header {
  align-items: center;
  height: 100%; }

.logo-site {
  width: 196px;
  margin-right: 30px; }

.logo__description {
  top: -10px;
  left: 50px;
  line-height: .9;
  font-family: RBI;
  font-size: 25px; }
  .logo__description span {
    color: #89C645; }
    .logo__description span:first-child {
      color: #004D94; }

.menus {
  display: flex;
  flex-direction: column;
  width: calc(100% - 226px); }

.phone-with-mode {
  align-items: flex-end;
  margin-right: auto; }

.first-menu {
  align-items: flex-end;
  line-height: 1;
  margin-bottom: 20px; }

.top-menu {
  width: calc(100% - 314px - 180px);
  justify-content: space-between;
  font-size: 14px; }
  .top-menu a {
    color: #454545; }

.header-phone {
  color: #0038FF !important;
  font-family: EB;
  margin-right: 18px; }

.company-work-mode {
  font-size: 14px;
  font-family: ER;
  color: #757575;
  opacity: 0;
  z-index: -1; }

.catalog-header {
  justify-content: space-between;
  font-size: 17px; }
  .catalog-header li {
    position: relative; }
    .catalog-header li .subspan {
      width: 9px;
      height: 5px;
      position: absolute;
      top: 55%;
      left: calc(100% + 5px);
      transform: translate(0, -50%);
      width: 9px;
      height: 5px;
      background: url("../images/svg/cm.svg") no-repeat 0 0;
      background-size: 100%; }
    .catalog-header li .sub-menu {
      position: absolute;
      display: none;
      border: 1px solid #0038ff;
      top: 100%;
      left: 0;
      list-style-type: none;
      padding: 0 !important;
      z-index: 200;
      background: #fff;
      padding: 10px 20px 10px 15px;
      line-height: 2;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      width: 300px;
      white-space: normal;
      line-height: 1; }
      .catalog-header li .sub-menu li {
        border-bottom: 1px solid rgba(204, 204, 204, 0.5);
        padding: 10px 15px; }
        .catalog-header li .sub-menu li:hover {
          background: #EBF1F2; }
        .catalog-header li .sub-menu li.active {
          background: #f8f8f8; }
        .catalog-header li .sub-menu li .subspan {
          background: url("../images/svg/sub.svg") no-repeat 0 0;
          background-size: 100%;
          width: 6px;
          height: 10px; }
        .catalog-header li .sub-menu li:last-child {
          border-bottom: 0; }
      .catalog-header li .sub-menu ul {
        display: block;
        top: 0;
        padding-right: 15px;
        left: calc(100% + 20px);
        box-shadow: none;
        font-size: 14px;
        display: none; }
        .catalog-header li .sub-menu ul a {
          color: #757575; }
        .catalog-header li .sub-menu ul::before, .catalog-header li .sub-menu ul::after {
          display: none; }
  .catalog-header a {
    color: #212121;
    display: block; }

.toggler, .pwm-mobile, .close-button {
  display: none; }

/*MEDIA*/
@media (max-width: 1295px) {
  .logo-site {
    margin-right: 0; }
  .toggler {
    display: block;
    width: 40px;
    height: 30px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center; }
    .toggler span {
      width: 35px;
      height: 4px;
      background: #0038ff;
      display: block;
      position: relative; }
      .toggler span::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #0038ff;
        left: 0;
        top: -8px;
        transition: all .4s; }
      .toggler span::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #0038ff;
        left: 0;
        bottom: -8px;
        transition: all .4s; }
      .toggler span.active {
        background: transparent; }
        .toggler span.active::before {
          top: 0;
          transform: rotate(-135deg); }
        .toggler span.active::after {
          bottom: 0;
          transform: rotate(135deg); }
  .menus {
    padding-top: 52px;
    position: fixed;
    width: 400px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    left: 0;
    top: 0;
    left: -500px;
    z-index: 1000;
    background: #fff;
    align-items: center;
    box-shadow: inset 0 0 400px rgba(0, 56, 255, 0.2), 0 0 20px rgba(0, 0, 0, 0.2);
    transition: all .4s; }
    .menus.active {
      left: 0; }
  .first-menu {
    width: 280px; }
  .top-menu {
    font-size: 1rem;
    width: 100%;
    flex-flow: column;
    line-height: 1.5; }
  .second-menu {
    width: 280px; }
  .catalog-header {
    flex-flow: column;
    line-height: 1.5; }
    .catalog-header li .subspan {
      display: none !important; }
  .phone-with-mode {
    margin-right: 0;
    display: none; }
  .sub-menu {
    display: none !important; }
  .pwm-mobile {
    display: block;
    margin-left: auto;
    margin-right: 20px; }
    .pwm-mobile.hx-width {
      display: none; }
  .pwm-phone {
    color: #0038ff !important;
    font-family: RB; }
  .pwm-work-mode {
    color: #454545;
    opacity: 0.7;
    display: none; } }

@media (max-width: 767px) {
  .logo__description {
    font-size: 14px;
    top: -7px;
    left: 27px; }
  .logo-site {
    width: 150px; }
    .logo-site img {
      width: 50px; }
  header {
    height: 60px; }
  .wrapper {
    top: 60px; }
  .pwm-phone {
    font-size: 14px; }
  .pwm-work-mode {
    font-size: 14px;
    line-height: 1; }
  .toggler {
    width: 30px;
    border: 1px solid rgba(0, 56, 255, 0.5);
    border-radius: 5px; }
    .toggler span {
      width: 20px; } }

@media (max-width: 480px) {
  .close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #0038ff;
    position: absolute;
    top: 20px;
    right: 20px; }
    .close-button span {
      width: 20px;
      height: 4px;
      position: relative; }
      .close-button span::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(-135deg);
        background: #0038ff; }
      .close-button span::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(135deg);
        background: #0038ff; }
  .menus {
    width: 100%; } }

@media (max-width: 380px) {
  .pwm-mobile {
    margin-left: 0;
    margin-right: 0;
    display: none; }
    .pwm-mobile.hx-width {
      display: block;
      width: 280px;
      margin-top: 15px; }
  .toggler {
    margin-left: auto; } }

.container-footer {
  justify-content: space-between;
  padding-bottom: 55px; }

footer {
  background: linear-gradient(0deg, #0D1217, #0D1217), #F7F7F7;
  padding-top: 68px;
  color: #fff; }

.site-footer-name {
  font-size: 14px;
  width: 195px;
  line-height: 1.2;
  margin-top: 15px; }

.footer-address {
  width: 277px; }

.footer-menu li {
  line-height: 2; }

.footer-menu a {
  color: rgba(255, 255, 255, 0.8) !important; }

.fi__head {
  margin-bottom: 15px;
  font-family: ER;
  font-size: 20px; }
  .fi__head.opacityzero {
    opacity: 0; }

.footer-phone {
  color: #fff !important;
  font-family: RB;
  display: block; }

.footer-wmode {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5); }

.socials {
  margin-top: 25px; }
  .socials a {
    margin-right: 10px; }
    .socials a:last-child {
      margin-right: 0; }

.footer-email {
  color: #fff !important;
  border-bottom: 1px solid #fff;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 19px; }

.footer-bottom {
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 19px;
  border-top: 1px solid rgba(255, 255, 255, 0.3); }
  .footer-bottom p {
    margin-bottom: 0;
    font-size: 14px;
    color: #fff;
    opacity: .5; }

.dc__name {
  font-size: 14px;
  color: #fff;
  opacity: .5; }

@media (max-width: 1000px) {
  .dc {
    margin-top: 10px; }
  .footer-bottom p br {
    display: none; }
  .footer__item:first-child {
    display: none; } }

@media (max-width: 848px) {
  .container-footer {
    padding-bottom: 30px; }
  footer {
    padding-top: 30px; }
  .footer-address {
    font-size: 14px;
    width: 240px; }
  .footer-email {
    font-size: 14px; }
  .socials {
    margin-top: 0; }
  .fi__head {
    display: none; }
  .footer__item {
    display: none; }
    .footer__item:last-child {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between; } }

@media (max-width: 730px) {
  .footer__item:last-child {
    flex-flow: column; }
  .footer-phone, .footer-email {
    width: 240px; }
  .footer-email {
    margin-bottom: 12px;
    border-bottom: 0; }
  .socials {
    width: 240px;
    margin-top: 15px; }
  .footer-bottom p {
    font-size: 12px; } }

.home-slider {
  position: relative;
  height: 696px;
  margin-bottom: 50px !important;
  border-bottom: 1px solid #D9DDE8; }
  .home-slider .slick-list, .home-slider .slick-track {
    height: 100%; }

.slick-slide:focus {
  outline: none; }

.slide-text-box {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding-top: 70px; }

.slide-image-box {
  width: 1057px;
  right: 0;
  height: 696px; }
  @media (min-width: 1930px) {
    .slide-image-box {
      width: 50% !important; } }
  .slide-image-box img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    z-index: -1; }

.slide-description {
  font-size: 18px;
  color: #757575;
  margin-top: 10px; }

.slide-name_short {
  font-size: 24px;
  font-family: ER; }

.slide-slogan {
  width: 307px;
  color: #fff;
  background: rgba(0, 56, 255, 0.7);
  bottom: 50px;
  left: 450px;
  padding: 35px 30px;
  min-height: 246px; }
  .slide-slogan .slick-dots {
    width: 100%;
    left: 0;
    bottom: 30px;
    padding-left: 30px;
    display: flex; }
    .slide-slogan .slick-dots li {
      width: 8px;
      height: 8px;
      background: #fff;
      border-radius: 50%; }
      .slide-slogan .slick-dots li.slick-active {
        background: #FAFF00; }

.slide-slogan__header {
  font-size: 27px;
  font-family: ER;
  line-height: 1;
  margin-bottom: 8px; }

.slider-bottons {
  margin-top: 60px;
  margin-bottom: 88px; }

.slide-btn {
  width: 165px; }

.sb-bordered {
  width: 195px;
  margin-left: 30px; }

.companys-products {
  color: #757575;
  align-items: center;
  font-size: 18px;
  cursor: pointer; }

.cp__mouse {
  margin-right: 10px; }

/**/
@media (max-width: 1918px) {
  .slide-image-box {
    width: 55%; } }

@media (max-width: 1366px) {
  .slide-image-box {
    width: 58%; } }

@media (max-width: 1322px) {
  .slide-slogan {
    left: auto;
    right: 15px; }
  .slide-title {
    font-size: 35px; } }

@media (max-width: 1250px) {
  .home-slider,
  .slide-image-box {
    height: 496px; }
  .slider-bottons {
    margin-top: 30px;
    margin-bottom: 30px; }
  .slide-description {
    font-size: 1rem; }
  .slide-text-box {
    padding-top: 40px; }
  .slide-name_short {
    font-size: 18px; }
  .slide-title {
    font-size: 32px; } }

@media (max-width: 1010px) {
  .slide-image-box {
    width: 50%; }
  .slide-text-box {
    width: 50%;
    padding-right: 10px; } }

@media (max-width: 840px) {
  .slide-text-box {
    width: 100%;
    color: #fff; }
  .slide-title {
    font-size: 31px;
    color: #fff;
    width: 80%; }
    .slide-title br {
      display: none; }
  .slide-description {
    color: #fff;
    width: 60%; }
  .companys-products {
    display: none; }
  .slide-image-box {
    width: 100%;
    height: 630px; }
    .slide-image-box::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.6); }
  .home-slider {
    height: 630px; }
  .slide-slogan {
    left: 15px;
    right: unset;
    bottom: 25px; }
  .container-slide-textcontent {
    position: relative;
    z-index: 20; }
  .sb-bordered {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3px);
    color: #fff !important;
    border: 1px solid #fff; } }

@media (max-width: 782px) {
  .slide-image-box {
    height: 655px; }
  .home-slider {
    height: 655px; }
  .slide-description {
    width: 80%; } }

@media (max-width: 575px) {
  .slide-description, .slide-title {
    width: 100%; } }

@media (max-width: 440px) {
  .slide-slogan {
    width: auto;
    min-height: auto;
    padding: 0;
    background: none; }
    .slide-slogan .slick-dots {
      bottom: 0; }
  .slide-slogan__header, .slide-slogan__desc {
    display: none; }
  .slider-bottons {
    flex-flow: column; }
  .sb-bordered {
    margin-left: 0;
    margin-top: 20px; }
  .slide-btn {
    width: 195px; }
  .home-slider,
  .slide-image-box {
    height: 550px; } }

@media (max-width: 380px) {
  .slide-title {
    font-size: 25px; } }

.prodaction__head {
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px; }

.production__titles h1, .production__titles h2 {
  margin-top: 4px;
  margin-bottom: 0; }

.produce-for-you {
  color: #757575; }

.product-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px; }

.grid__item {
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, #EBF1F2, #EBF1F2), #F5F3EC;
  height: 320px;
  padding: 20px 15px 31px 30px; }
  @media (min-width: 640px) {
    .grid__item:hover .circle-arrow {
      opacity: 1; } }

#item57 {
  grid-column: span 2; }

.circle-arrow {
  margin-top: auto;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0038FF;
  border-radius: 50%;
  transition: all .4s;
  opacity: 0; }
  .circle-arrow.green {
    background: #72AD22; }

.grid-image {
  bottom: 20px;
  right: 40px; }

.grid-desc {
  width: 262px;
  line-height: 1.2;
  color: #757575;
  font-family: RR;
  font-size: 1rem; }

.grid-name {
  font-family: ER;
  font-size: 18px;
  color: #212121;
  line-height: 1.2;
  margin-bottom: 5px; }

.grid__item-main {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0038FF;
  color: #fff !important;
  text-align: center; }
  .grid__item-main.green {
    background: #72AD22; }

.homecategories {
  margin-top: 50px;
  margin-bottom: 80px; }

.modular-solutions {
  justify-content: space-between;
  padding-right: 38px; }

.module__info {
  width: 45%;
  display: flex;
  flex-direction: column; }
  .module__info a {
    width: 198px;
    padding-left: 32px;
    padding-right: 14px;
    margin-top: 25px; }

.info__content {
  color: #454545; }

.modular-widget {
  margin-top: 50px;
  margin-bottom: 73px; }

.single-with-children {
  background: linear-gradient(0deg, #EEF2F6, #EEF2F6), #EEF2F6;
  padding-top: 65px;
  padding-bottom: 64px; }

.single-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px; }

.single__item {
  min-height: 320px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 30px; }

.single__thumnale {
  top: 0;
  right: 0; }

.single__name {
  width: 218px;
  font-family: ER;
  color: #212121 !important;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 15px; }

.child-link {
  color: #757575;
  line-height: 1.5; }

.parent-link {
  margin-top: auto;
  display: block;
  line-height: 1; }
  .parent-link span {
    color: #0038FF;
    border-bottom: 1px solid #0038ff; }

.pojects-section {
  margin-top: 70px; }
  .pojects-section.projects-on-category {
    margin-bottom: 140px; }

.project__info {
  width: 50%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 36px 130px 44px 39px;
  position: relative;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc; }
  .project__info::before {
    content: "";
    position: absolute;
    width: 10px;
    height: calc(100% + 2px);
    top: -1px;
    left: 0;
    background: #0038FF; }

.project__img {
  position: relative;
  width: 50%; }
  .project__img img {
    width: 100%; }

.project__name {
  font-size: 20px;
  line-height: 1.2;
  font-family: ER; }

.project__date {
  color: #757575;
  margin-bottom: 5px; }

.project__desc {
  color: #757575;
  margin-top: 10px;
  line-height: 1.2; }

.project__detail {
  display: flex;
  align-items: center;
  margin-top: auto;
  color: #0038FF; }
  .project__detail span {
    margin-right: 10px; }

.projects-carousel {
  margin-bottom: 0 !important; }

.project-dots .slick-dots {
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 0;
  margin-top: 20px; }

.project-dots li {
  width: 10px;
  height: 10px;
  background: #C4C4C4;
  border-radius: 50%; }
  .project-dots li.slick-active {
    background: #0038FF; }

@media (max-width: 1350px) {
  .single__thumnale {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end; }
    .single__thumnale img {
      width: 70%; }
  #item57 {
    grid-column: span 1; }
  .product-list-grid {
    grid-template-columns: repeat(3, 1fr); }
  .grid__item-main {
    height: 320px; } }

@media (max-width: 1170px) {
  .project__img img {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover; } }

@media (max-width: 1114px) {
  .single__thumnale {
    display: none; }
  .module__img {
    width: 46%;
    display: flex;
    align-items: center; }
    .module__img img {
      width: 100%; } }

@media (max-width: 1055px) {
  .product-list-grid {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 951px) {
  .project__info {
    padding: 40px 20px 40px 30px; }
  .module__img {
    width: 413px; }
    .module__img img {
      width: 100%; }
  .module__info {
    width: 100%;
    order: 2; } }

@media (max-width: 750px) {
  .project__info {
    width: 100%;
    padding: 15px; }
    .project__info::before {
      display: none; }
  .project__img {
    width: 100%;
    order: -1; }
    .project__img img {
      position: static; }
  .homecategories {
    margin-bottom: 50px; }
  .single__item {
    padding: 15px; }
  .single__name {
    font-size: 25px; }
  .single-grid {
    grid-template-columns: 1fr; }
  .produce-for-you {
    font-size: 14px; } }

@media (max-width: 675px) {
  .product-list-grid {
    grid-template-columns: 1fr; } }

@media (max-width: 640px) {
  .prodaction__head {
    flex-flow: column;
    align-items: flex-start; }
  .btn-prod {
    font-size: 1rem;
    margin-top: 15px; } }

@media (max-width: 390px) {
  .grid__item-main {
    height: auto;
    padding: 20px 0; } }

@media (max-width: 350px) {
  .grid__item {
    padding: 15px; } }

.site-contacts {
  height: 535px; }

.contacts-btn {
  width: 150px;
  margin-top: 25px; }

.contacts-title {
  margin-top: 5px;
  margin-bottom: 15px; }

.contacts__item {
  height: 100%;
  width: 50%; }
  .contacts__item.c-left {
    z-index: -1;
    top: 0;
    left: 0;
    background: #fff; }
  .contacts__item.c-right {
    top: 0;
    right: 0;
    background: yellow;
    display: flex;
    align-items: stretch; }
    .contacts__item.c-right iframe {
      width: 100% !important;
      height: 100% !important;
      border-right: 0 !important; }

.contacts__info {
  padding-top: 70px; }

.site-mode {
  color: #7A8086; }

.adr-head {
  color: #7A8086;
  margin-top: 15px; }

.contacts-phone {
  font-family: RB;
  font-size: 24px;
  color: #0038FF; }

.site-address {
  font-size: 20px;
  font-family: RB;
  color: #454545; }

@media (max-width: 1180px) {
  .contacts__item.c-right {
    position: relative;
    width: 100%;
    height: 535px; }
  .site-contacts {
    height: auto; }
  .contacts__info {
    padding-top: 30px;
    padding-bottom: 30px; }
  .contacts-title br:last-child {
    display: none; } }

@media (max-width: 450px) {
  .contacts-title br {
    display: none; }
  .site-address {
    font-size: 1rem; } }

.category-child {
  justify-content: space-between; }

.category-child__box {
  width: 308px; }
  .category-child__box a {
    display: block; }

.child-children {
  width: calc(100% - 308px - 144px); }

.child-children {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.ch_wrap {
  font-size: 18px;
  line-height: 1.2;
  width: calc(50% - 19px);
  height: auto; }

.ch__item {
  margin-bottom: 15px; }
  .ch__item:last-child {
    margin-bottom: 0; }

.p-name {
  color: #212121; }

.p-desc {
  font-size: 16px;
  color: #757575; }

.category-head {
  padding-bottom: 50px;
  border-bottom: 1px solid #D9DDE8; }
  .category-head h1 {
    margin-top: 0;
    margin-bottom: 0; }

.category-child__name {
  font-size: 30px;
  font-family: ER;
  border-bottom: 1px solid #212121;
  color: #212121;
  display: inline !important; }

.category-child-wrap {
  padding-top: 60px;
  padding-bottom: 97px;
  border-bottom: 1px solid #D9DDE8; }
  .category-child-wrap:last-child {
    border-bottom: 0; }

.category-child__img {
  margin-top: 15px; }

.cat-root-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 60px;
  margin-bottom: 102px; }

.container-grid-children {
  width: calc(100% - 338px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px; }
  .container-grid-children.ch-root {
    width: 100%;
    grid-template-columns: repeat(4, 1fr); }

.category-sidebar {
  width: 308px;
  background-repeat: no-repeat;
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED; }

.cgc__item {
  background: linear-gradient(0deg, #EBF1F2, #EBF1F2), #F5F3EC;
  display: flex;
  flex-flow: column;
  padding: 20px 30px;
  justify-content: space-between;
  min-height: 320px;
  transition: all .4s; }
  .cgc__item .p-desc br {
    display: none; }
  @media (min-width: 640px) {
    .cgc__item:hover .cgc__image img {
      transform: scale(1.1, 1.1); } }

.cgc__name span {
  font-size: 18px;
  color: #212121;
  font-family: ER;
  line-height: 1.1; }

.cgc__image {
  display: flex;
  justify-content: center; }
  .cgc__image img {
    transition: all .4s; }

.adventages {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 30px;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 34px;
  width: 100%; }

.adventages__name {
  font-size: 18px;
  font-family: ER;
  margin-top: 10px;
  margin-bottom: 5px; }

.adventages__desc {
  color: #757575;
  font-family: RR;
  line-height: 1.2; }

.category-short-description {
  width: 982px;
  margin-bottom: 60px; }

.advents-container {
  margin-top: 80px; }

.catalog-sub-menu {
  display: none; }
  .catalog-sub-menu li {
    background: #fff !important;
    border-bottom: 1px solid #dedede; }
    .catalog-sub-menu li:last-child {
      border-bottom: none; }
    .catalog-sub-menu li a {
      font-size: 16px !important;
      padding: 15px 21px 15px 29px !important;
      pointer-events: unset !important;
      color: #454545;
      background: #f8f8f8; }

.sidebar-menu {
  font-family: ER; }

.sidebar-menu > li {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #EDEDED;
  line-height: 1.2; }
  .sidebar-menu > li.active {
    background: #EDEDED; }
    .sidebar-menu > li.active a {
      pointer-events: none; }
  .sidebar-menu > li a {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 23px 21px 23px 29px;
    width: 100%;
    font-size: 18px;
    color: #212121; }

.cpn {
  margin-bottom: 0;
  margin-top: 0;
  font-family: ER;
  font-size: 30px;
  padding: 23px 21px 23px 29px;
  border-bottom: 1px solid #EDEDED;
  border-top: 1px solid #EDEDED; }

.mobile-parent-menu {
  width: 100%;
  display: none; }

.industry-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px; }
  .industry-grid hr {
    grid-column: span 3;
    height: 1px;
    width: 100%;
    background: #D9DDE8;
    margin: 0; }

.industry-grid__img {
  overflow: hidden; }
  .industry-grid__img img {
    transition: all 1s; }
  @media (min-width: 640px) {
    .industry-grid__img:hover img {
      transform: scale(1.1, 1.1); } }

.industry__title {
  font-size: 18px;
  font-family: ER;
  margin-top: 20px;
  color: #212121; }

.industry-title {
  margin-top: 0; }

.industry-container {
  margin-top: 50px;
  margin-bottom: 90px; }

.system-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  margin-bottom: 80px; }

.system__item {
  padding: 31px 30px 37px 30px;
  border: 1px solid #D9DDE8;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F5F3EC; }

.sys__title {
  margin-top: 20px;
  color: #212121;
  font-family: ER; }

.system-self {
  margin-top: 60px; }

.cat-self a {
  color: #212121; }

/*STANDART*/
.container-grid-standart {
  padding-top: 30px; }
  .container-grid-standart .product-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px; }
  .container-grid-standart .product-box__name {
    font-size: 18px;
    color: #212121;
    font-family: EB;
    margin-top: 10px; }
  .container-grid-standart .pagination > .active > a,
  .container-grid-standart .pagination > .active > span,
  .container-grid-standart .pagination > .active > a:hover,
  .container-grid-standart .pagination > .active > span:hover,
  .container-grid-standart .pagination > .active > a:focus,
  .container-grid-standart .pagination > .active > span:focus {
    background: #0038FF;
    border: 1px solid #0038FF;
    color: #fff; }
  .container-grid-standart .pagination > li > a, .container-grid-standart .pagination > li > span {
    color: #0038FF; }

.properties-flex {
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 5px;
  align-items: center; }
  .properties-flex:last-child {
    margin-bottom: 0; }

.standart-product-item {
  padding: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); }

.properties__key {
  font-size: 14px;
  font-family: RR;
  color: #757575; }

.properties__value {
  font-size: 14px;
  font-family: RB;
  color: #494949; }

.sp__img img {
  width: 100%; }

/*////////////////////////////////////////////////////////////////////////*/
@media (max-width: 1350px) {
  .industry-grid__img img {
    width: 100%; } }

@media (max-width: 1295px) {
  .container-grid-children {
    grid-template-columns: repeat(2, 1fr); }
    .container-grid-children.ch-root {
      grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 1250px) {
  .container-grid-standart .product-list {
    grid-template-columns: repeat(3, 1fr); }
  .container-grid-standart .product-box__name {
    font-size: 1rem; } }

@media (max-width: 1150px) {
  .adventages {
    grid-template-columns: repeat(2, 1fr); }
  .adventages__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .adventages__img {
    margin-right: 10px;
    margin-bottom: 0; } }

@media (max-width: 991px) {
  .system-self {
    margin-top: 20px; }
  .industry-grid {
    grid-gap: 15px; }
    .industry-grid hr {
      display: none; }
  .industry__title {
    font-size: 1rem;
    margin-top: 10px;
    line-height: 1; }
  .mobile-parent-menu {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px; }
  .mpm-link {
    align-items: center; }
    .mpm-link span {
      margin-right: 5px; }
  .category-sidebar {
    display: none;
    border-right: 0;
    border-left: 0;
    width: 100%;
    margin-bottom: 20px; }
    .category-sidebar .cpn {
      display: none; }
  .container-grid-children {
    width: 100%; }
    .container-grid-children.ch-root {
      margin-top: 20px; }
  .cat-root-container {
    margin-top: 0; } }

@media (max-width: 930px) {
  .system-grid {
    grid-template-columns: repeat(3, 1fr); }
  .container-grid-children.ch-root {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 920px) {
  .container-grid-standart .product-list {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 800px) {
  .adventages__item {
    flex-flow: column; }
  .adventages__img {
    margin-right: 0;
    margin-bottom: 10px; } }

@media (max-width: 767px) {
  .industry-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px; }
  .system-grid {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 675px) {
  .parent-inline h2 {
    text-align: center; }
  .cat-root-container {
    margin-bottom: 30px; }
  .adventages {
    grid-template-columns: 1fr; }
  .advents__info-wrap {
    width: 297px;
    text-align: center; } }

@media (max-width: 596px) {
  .container-grid-children {
    grid-template-columns: 1fr; }
    .container-grid-children.ch-root {
      grid-template-columns: 1fr; } }

@media (max-width: 575px) {
  .container-grid-standart .product-box__name {
    font-size: 14px; }
  .properties__key, .properties__value {
    font-size: 11px; } }

@media (max-width: 500px) {
  .system-grid {
    grid-template-columns: 1fr; }
  .container-grid-standart .product-list {
    grid-template-columns: 1fr; }
  .container-grid-standart .product-box__name {
    font-size: 18px; }
  .properties__key, .properties__value {
    font-size: 14px; }
  .category-head {
    padding-bottom: 20px; } }

@media (max-width: 420px) {
  .industry-container {
    margin-top: 20px;
    margin-bottom: 30px; }
  .industry-grid {
    grid-template-columns: 1fr; } }

.view-categoty-flex {
  justify-content: space-between;
  padding-top: 20px; }
  .view-categoty-flex .slick-dots {
    display: flex;
    justify-content: center;
    width: 100%; }
    .view-categoty-flex .slick-dots li {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: #C4C4C4; }
      .view-categoty-flex .slick-dots li.slick-active {
        background: #0038FF; }

.vcf__info {
  width: calc(100% - 787px);
  padding-top: 10px; }

.vcf__image {
  background: #EBF1F2;
  width: 645px; }

.vcf__image_item {
  height: 460px; }

.vcf__image__thumb {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .vcf__image__thumb.bordered {
    background: #fff;
    border: 1px solid #ececec; }

.vcf__short {
  color: #454545;
  margin-top: 20px;
  font-family: RR; }

.calculation {
  width: 195px;
  margin-right: 30px; }

.questionnaire {
  width: 195px; }

.vcf__buttons {
  margin-top: 29px; }

.sigle-category-description {
  margin-top: 80px;
  width: calc(100% - 645px);
  color: #454545; }
  .sigle-category-description table {
    width: 100%;
    font-family: RR; }
    .sigle-category-description table tr {
      border-bottom: 2px solid #E9EAF3; }
      .sigle-category-description table tr td {
        padding-top: 5px;
        padding-bottom: 5px; }

.single-nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-top: 74px;
  font-size: 20px;
  border-bottom: 1px solid #D9DDE8; }
  .single-nav li {
    padding-bottom: 16px;
    margin-right: 60px;
    position: relative;
    cursor: pointer; }
    .single-nav li::before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background: transparent;
      transition: all .4s; }
    .single-nav li:last-child {
      margin-right: 0; }
    .single-nav li.active {
      color: #0038FF; }
      .single-nav li.active::before {
        background: #0038FF; }

.tr-header {
  display: flex;
  font-size: 18px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #D9DDE8; }
  .tr-header .tr-product__item {
    color: #212121; }

.data-product {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D9DDE8; }

.td-name {
  color: #333;
  margin-bottom: 10px;
  max-width: 160px;
  font-size: 14px;
  opacity: 0.7; }

.td-name-product {
  margin-bottom: 10px;
  font-size: 14px; }

.td-main {
  color: #212121; }

.tr-product__item {
  line-height: 1;
  padding-bottom: 30px;
  padding-top: 30px;
  font-family: ER;
  color: #757575; }
  .tr-product__item a {
    text-decoration: underline !important;
    font-family: EB; }
  .tr-product__item.last-of-type {
    margin-right: 0; }

.tabPane {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .tabPane.active {
    position: relative;
    z-index: 10;
    opacity: 1; }

#product-box .page a {
  color: #0038FF; }

#product-box .page.active a {
  background: #0038FF;
  border-color: #0038FF;
  color: #fff; }

#product-box li i {
  color: #0038FF; }

#product-box li.disabled i {
  color: #ccc; }

.equipment__table table {
  border: none !important; }
  .equipment__table table td {
    border: none;
    border-right: 1px solid #D9DDE8;
    padding: 34px 0;
    vertical-align: top;
    padding-left: 20px;
    padding-right: 15px; }
    .equipment__table table td:last-child {
      border-right: 0; }
    .equipment__table table td:first-child {
      padding-left: 0; }
  .equipment__table table tr {
    border-bottom: 1px solid #D9DDE8;
    color: #757575; }
    .equipment__table table tr:first-child {
      color: #212121;
      font-size: 18px;
      line-height: 1.2; }

.work_principle__table table {
  border: none !important; }
  .work_principle__table table td {
    border: none;
    padding: 20px 0; }
  .work_principle__table table tr {
    border-bottom: 1px solid #D9DDE8; }

.versions__table table {
  border: none !important;
  table-layout: fixed; }
  .versions__table table tr {
    border-bottom: 1px solid #D9DDE8;
    display: flex; }
  .versions__table table td {
    width: auto !important;
    border: none;
    padding: 34px 0; }
    .versions__table table td:first-child {
      width: 55% !important;
      padding-right: 50px; }
    .versions__table table td:last-child {
      width: 45% !important; }
    .versions__table table td img {
      max-width: 100% !important; }
    .versions__table table td:last-child {
      display: flex;
      justify-content: space-between; }

.doc-link__wrap {
  width: 100%; }
  .doc-link__wrap h2 {
    font-family: EB;
    color: #494949; }

.filesize-box {
  color: #454545;
  line-height: 1; }

.linksWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px; }

.li_wrap p {
  position: relative;
  padding-left: 35px; }
  .li_wrap p::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 40px; }
  .li_wrap p.pdf-box::before {
    background: url("../images/svg/pdf.svg") no-repeat center center;
    background-size: 30px 40px; }
  .li_wrap p.word-box::before {
    background: url("../images/svg/docx.svg") no-repeat center center;
    background-size: 30px 40px; }
  .li_wrap p a {
    color: #0038FF; }

.doc-link__wrap h2 {
  width: 100%; }

.doc-link__wrap .linksWrapper {
  width: 100%; }

.pdf-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px; }

@media (max-width: 1250px) {
  .single-nav {
    font-size: 1rem; }
    .single-nav li {
      padding-bottom: 5px; }
  .vcf__info {
    width: calc(50% - 30px); }
  .vcf__image {
    width: 50%; } }

@media (max-width: 1200px) {
  .tr-header .tr-product__item {
    font-size: 14px; }
  .data-product {
    font-size: 14px; } }

@media (max-width: 1050px) {
  .sigle-category-description {
    width: 100%; }
    .sigle-category-description table tr {
      border: none; }
      .sigle-category-description table tr td {
        border: 1px solid #ededed;
        padding: 10px 5px; }
  .tr-product__item {
    width: 100%;
    padding: 15px 10px;
    position: relative;
    color: #212121;
    border: 1px solid #ccc;
    margin-top: -1px;
    margin-left: -1px; }
  .product-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr); }
  .data-product {
    flex-flow: column;
    align-items: flex-start;
    padding: 15px 0;
    padding-left: 1px; }
  .tr-header {
    display: none; }
  .vcf__info {
    width: 100%;
    order: 2; }
  .vcf__image {
    width: 645px; } }

@media (max-width: 1010px) {
  .linksWrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px; }
  .single-nav li {
    margin-right: 20px; } }

@media (max-width: 991px) {
  .versions__table table td:first-child {
    width: 100% !important;
    justify-content: flex-start; }
  .versions__table table td:last-child {
    order: -1;
    padding-bottom: 0;
    width: 100% !important;
    justify-content: flex-start; }
  .versions__table table tr {
    flex-flow: column; } }

@media (max-width: 850px) {
  .equipment__table table tr {
    font-size: 14px !important; }
  .equipment__table table td {
    padding: 15px 10px; }
  .product-list {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 810px) {
  .pojects-section.projects-on-category {
    margin-bottom: 60px; }
  .single-nav {
    flex-flow: column;
    margin-top: 0; }
    .single-nav li {
      margin-right: 0;
      padding: 10px;
      background: #ededed;
      margin-bottom: 5px; }
      .single-nav li.active {
        background: #0038ff;
        color: #fff; }
        .single-nav li.active::before {
          display: none; } }

@media (max-width: 675px) {
  .vcf__image {
    width: 100%; } }

@media (max-width: 640px) {
  .pdf-container {
    grid-template-columns: 1fr;
    max-height: 440px;
    overflow: hidden;
    overflow-y: auto;
    border-bottom: 1px solid #dedede;
    padding-bottom: 10px; }
  .view-categoty-flex .slick-dots {
    bottom: -20px; }
  .equipment__table table tr {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    border: none; }
    .equipment__table table tr:first-child {
      display: none; }
    .equipment__table table tr td {
      border: 1px solid #dedede !important;
      padding: 15px 10px !important;
      display: flex;
      flex-flow: column;
      width: 100% !important;
      border: none;
      position: relative;
      font-size: 1rem; }
      .equipment__table table tr td.emptyCeil {
        display: none; }
      .equipment__table table tr td::before {
        content: attr(data-head);
        width: 100%;
        font-family: EB;
        color: #212121;
        font-size: 14px; } }

@media (max-width: 696px) {
  .linksWrapper {
    grid-template-columns: 1fr;
    grid-gap: 15px; } }

@media (max-width: 450px) and (max-width: 850px) {
  .product-list {
    grid-template-columns: repeat(1, 1fr); } }

@media (max-width: 450px) {
  .vcf__image__thumb img {
    width: 100%; }
  .vcf__buttons {
    flex-flow: column; }
  .calculation {
    margin-right: 0;
    margin-bottom: 15px; }
  .vcf__image_item {
    height: auto;
    padding: 30px 0; } }

@media (max-width: 400px) {
  .versions__table table td {
    font-size: 14px; }
    .versions__table table td img {
      width: 40%;
      height: auto; }
    .versions__table table td.single-td img {
      width: 100%; } }

.product-images {
  width: 644px;
  justify-content: space-between; }

.img__thumbnales {
  width: 82px;
  margin-bottom: -10px; }

.thumbnales__item {
  height: 64px;
  width: 82px;
  margin-bottom: 10px;
  cursor: pointer; }

.thumbnales__item-flex {
  border: 1px solid #dedede;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.img__main {
  width: 532px; }
  .img__main .prev, .img__main .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    z-index: 20;
    cursor: pointer; }
  .img__main .next {
    left: auto;
    right: 10px; }

.slick-current .thumbnales__item-flex {
  border: 2px solid #89C645; }

.img__main_item {
  border: 1px solid #cecece; }
  .img__main_item img {
    width: 100%; }

.product-view-container {
  padding-top: 15px;
  justify-content: space-between; }

.product-information {
  width: calc(100% - 757px);
  display: flex;
  flex-flow: column; }
  .product-information h1 {
    margin-top: 0;
    margin-bottom: 15px; }

.atribute-flex {
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #E9EAF3;
  font-family: RR;
  color: #454545;
  font-family: RR;
  line-height: 1;
  align-items: center; }

.destination {
  margin-top: 15px;
  font-family: RR; }

.destination__title {
  color: #454545; }

.dest__item {
  color: #212121; }

/* main content*/
.product-main-info {
  margin-top: 50px;
  margin-bottom: 80px; }

.products-nav {
  margin-bottom: 0;
  border-bottom: 1px solid #dedede; }

.product-nav .pagination-box {
  border-bottom: 1px solid #D9DDE8;
  display: flex;
  flex-wrap: wrap; }

.product-nav__item {
  cursor: pointer;
  padding-bottom: 10px;
  line-height: 1;
  margin-right: 40px;
  font-family: RR;
  position: relative;
  transition: all .3s;
  color: #212121; }
  .product-nav__item:last-child {
    margin-right: 0; }
  .product-nav__item.active {
    color: #0038FF; }
    .product-nav__item.active::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: #0038FF;
      left: 0;
      bottom: -1px; }

.pane-item {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .pane-item.active {
    position: relative;
    z-index: 20;
    opacity: 1; }

.specifications__box {
  justify-content: space-between; }

.sb__item {
  width: calc(50% - 15px); }
  .sb__item .atribute-flex {
    min-height: 50px;
    margin-bottom: 0;
    padding-bottom: 0; }
    .sb__item .atribute-flex:first-child {
      border-top: 1px solid #E9EAF3; }

.sp__head {
  font-size: 24px;
  color: #212121;
  font-family: ER;
  margin: 10px 0 10px;
  padding-top: 15px; }

.description-box {
  margin-top: 20px; }

.description-box__content {
  overflow: hidden;
  height: 66px;
  position: relative;
  transition: all .3s; }
  .description-box__content::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
    background: url("../images/svg/tuman.svg") no-repeat 0 0;
    background-size: cover; }
  .description-box__content.opened {
    overflow: visible;
    height: auto; }
    .description-box__content.opened::before {
      opacity: 0;
      z-index: -1; }

.open-content {
  display: inline-block;
  color: #0038FF;
  border-bottom: 1px solid #0038FF;
  line-height: 1;
  margin-top: 20px;
  cursor: pointer; }
  .open-content.toggled {
    margin-top: 5px; }

.description-images {
  margin-top: 30px;
  justify-content: space-between; }

.description-images__item {
  width: calc(50% - 7.5px); }
  .description-images__item p {
    font-size: 24px;
    font-family: ER; }
    .description-images__item p:last-child {
      margin-bottom: 0; }
    .description-images__item p img {
      width: 100%; }

/***/
@media (max-height: 1366px) {
  .img__main {
    width: 82.4%; }
  .product-images {
    width: 48.2%; }
  .product-information {
    width: calc(100% - 48.2% - 30px); } }

@media (max-width: 1200px) {
  .img__thumbnales {
    margin-top: 10px;
    margin-bottom: 0;
    margin-right: -10px; }
  .thumbnales__item {
    margin-bottom: 0;
    margin-right: 10px; }
  .img__main {
    width: 100%; }
  .img__thumbnales {
    width: 100%;
    order: 2; } }

@media (max-width: 991px) {
  .product-nav__item {
    margin-right: 20px; } }

@media (max-width: 960px) {
  .product-information {
    width: 100%;
    margin-top: 30px; }
    .product-information h1 {
      font-size: 24px; }
  .product-images {
    width: 644px; }
  .product-attributes {
    width: 644px; } }

@media (max-width: 810px) {
  .product-nav__item {
    margin-right: 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    background: #dedede; }
    .product-nav__item.active {
      background: #0038FF;
      color: #fff; }
      .product-nav__item.active::before {
        display: none; } }

.dimensions__box {
  padding-top: 30px; }

@media (max-width: 674px) {
  .sb__item {
    width: 100%; }
  .product-images {
    width: 100%; }
  .product-attributes {
    width: 100%; } }

.services-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 30px; }

.service__img {
  position: relative;
  overflow: hidden; }
  .service__img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    transition: all .4s; }
  .service__img img {
    width: 100%; }
  .service__img:hover::before {
    opacity: 0; }
  .service__img:hover .service__name {
    top: 0;
    left: 0;
    background: #EBF1F2;
    padding: 15px;
    width: 100%;
    color: #212121;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4); }

.service__name {
  color: #fff;
  top: 15px;
  left: 15px;
  width: 90%; }

.service__item {
  background: #EBF1F2;
  font-family: EB;
  color: #212121 !important; }

.service__info {
  padding: 15px 10px;
  line-height: 1.2;
  min-height: 87px;
  display: flex;
  align-items: center; }

.btn__service {
  color: #212121; }
  .btn__service span {
    border-bottom: 1px solid #212121; }

.list__item {
  padding: 10px 0;
  border-bottom: 1px solid #ececec;
  cursor: pointer; }
  .list__item span {
    border-bottom: 1px solid #212121; }

#serviceModal .modal-dialog {
  width: 1000px; }

#serviceModal .smc__img {
  margin-bottom: 15px; }

@media (max-width: 1030px) {
  #serviceModal .modal-dialog {
    width: calc(100% - 30px); } }

@media (max-width: 767px) {
  #serviceModal .modal-dialog {
    margin: 10px auto; } }

@media (max-width: 568px) {
  .smc__img img {
    width: 100%; } }

.ym__name {
  max-width: 276px;
  line-height: 1;
  display: block;
  color: #0038ff !important;
  font-family: EB;
  margin-top: 10px; }

.ym__mode {
  display: block;
  margin-top: 5px; }

.sp__title {
  height: 40px;
  width: 200px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 5px; }

.container-map {
  margin-top: 40px;
  margin-bottom: 40px; }

.list_projects .items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px; }

.list_projects .pm__img.project-pm-img {
  padding: 5px; }

.list_projects .pm__img.news__pm__img {
  padding: 10px; }

.list_projects .pm__img img {
  width: 100%; }

.pm__date {
  font-size: 14px;
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 10px; }

.pm__name {
  font-family: EB;
  font-size: 18px;
  line-height: 1; }

.pm__link {
  margin-left: auto;
  height: 45px;
  width: 120px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333 !important;
  transition: all .4s; }
  .pm__link:hover {
    border: 1px solid #333;
    color: #212121; }

.pm__short {
  margin-top: 15px; }

.pm__footer {
  margin-top: auto;
  padding: 10px; }

.pm__info {
  padding: 0 10px; }

.project-map-item {
  background: linear-gradient(0deg, #ebf1f2, #ebf1f2), #f5f3ec;
  padding-bottom: 15px;
  display: flex;
  flex-flow: column; }
  .project-map-item.pm__news {
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

.projects-list {
  margin-top: 30px; }
  .projects-list .summary {
    margin-bottom: 30px;
    opacity: 0.6; }

#map {
  border-top: 2px solid #0038ff; }

.project-photos-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-top: 30px;
  margin-bottom: 30px; }

.project-thumbnail {
  padding: 5px;
  border: 1px solid #ececec; }

.project__single-content {
  margin-bottom: 50px; }

@media (max-width: 1000px) {
  .pm__short {
    font-size: 14px;
    margin-top: 10px; }
  .pm__name {
    font-size: 16px; }
  .pm__date {
    font-size: 12px; }
  .pm__link {
    font-size: 14px; }
  .list_projects .items {
    grid-gap: 10px; } }

@media (max-width: 767px) {
  .list_projects .items {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 480px) {
  .list_projects .items {
    grid-template-columns: 1fr; } }

.pagecontent__sidebar {
  width: 300px;
  border: 1px solid #ececec;
  padding: 0 0 20px 0; }

.pagecontent__left {
  width: calc(100% - 330px); }

.page__content__flex {
  justify-content: space-between; }

.child__link {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  min-height: 50px;
  border-bottom: 1px solid #ececec;
  color: #0038ff !important; }
  .child__link span {
    border-bottom: 1px solid #0038ff; }

.gallery-thumbnails {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px; }

.thumbnail {
  margin-bottom: 0;
  cursor: pointer; }

.gallery-thumbnail {
  height: auto; }

.gallery-show__header {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ececec; }

.fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 20px; }
  .fields .form-group label {
    display: none; }
  .fields .textarea {
    display: flex; }
    .fields .textarea .form-group {
      width: 100%;
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap; }
    .fields .textarea textarea {
      box-shadow: none !important;
      border-radius: 0; }
  .fields .inputs-wrap .form-group {
    margin-bottom: 20px; }
    .fields .inputs-wrap .form-group:last-child {
      margin-bottom: 0; }
  .fields .inputs-wrap input {
    height: 40px;
    border-radius: 0;
    box-shadow: none !important; }

#politic {
  display: flex;
  line-height: 1;
  font-size: 14px;
  align-items: center; }
  #politic a {
    color: #333 !important; }
    #politic a span {
      border-bottom: 1px solid #0038ff;
      color: #0038ff !important; }
  #politic input {
    display: none; }
    #politic input:checked + label {
      background: #0038ff url("../images/svg/check.svg") no-repeat center center; }
  #politic label {
    width: 20px;
    height: 20px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer; }

.sender_box {
  display: flex;
  width: calc(50% - 10px);
  justify-content: space-between;
  margin-top: 15px; }

#form-button {
  border: none;
  border-radius: 50px;
  height: 45px;
  background: #0038ff;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff; }
  #form-button.disabled {
    pointer-events: none;
    opacity: 0.5; }

#about-form {
  margin-bottom: 50px;
  margin-top: 50px; }

.error {
  color: #a94442;
  font-size: 14px;
  width: 100%; }

.form-control.error {
  border: 1px solid #a94442 !important; }

.requsits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid #ececec;
  margin-top: 30px; }

.r-item {
  padding: 10px;
  color: 333;
  padding-right: 50px; }

.r-item__name {
  font-family: RB;
  font-size: 18px;
  margin-bottom: 5px; }

.r-item__desc {
  opacity: 0.7; }
  .r-item__desc ul {
    padding-left: 0;
    margin-bottom: 0; }

.doc-link {
  padding: 15px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  color: #0038ff; }
  .doc-link svg {
    margin-left: 15px; }

.textContentStance {
  margin-top: 30px;
  margin-bottom: 30px; }

.tableContentStance p {
  margin-bottom: 0; }

.tableContentStance table {
  width: 100%; }
  .tableContentStance table tr:first-child td {
    font-family: EB; }
  .tableContentStance table td {
    border: 1px solid #ccc;
    padding: 15px 10px; }
    .tableContentStance table td .td-before {
      display: none; }

.page-site-stance {
  padding-bottom: 50px; }

.content-site {
  margin-bottom: 80px; }

.contacts-map {
  margin-top: 30px;
  border-top: 2px solid #0038ff; }

.adr__left {
  width: 250px;
  margin-right: 30px; }

.adr__tr {
  padding: 15px 0; }

@media (max-width: 1200px) {
  .sender_box {
    width: 100%; } }

@media (max-width: 960px) {
  .requsits-grid {
    grid-template-columns: 1fr; }
  .r-item__desc ul {
    list-style: none; } }

@media (max-width: 900px) {
  .pagecontent__left {
    width: 100%; }
  .pagecontent__sidebar {
    width: 100%;
    order: -1;
    margin-bottom: 30px; }
  .childBox:last-child a {
    border-bottom: none; } }

@media (max-width: 767px) {
  .fields {
    grid-template-columns: 1fr;
    grid-gap: 20px; }
    .fields .textarea {
      height: 150px; }
  .gallery-thumbnails {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px; } }

@media (max-width: 640px) {
  .tableContentStance table td, .tableContentStance table tr {
    display: block; }
  .tableContentStance table tr:first-child {
    display: none; }
  .tableContentStance table tr {
    margin-bottom: 10px; }
  .td-before {
    display: block !important;
    font-family: EB;
    font-size: 12px; } }

@media (max-width: 584px) {
  .sender_box {
    flex-flow: column; }
  #form-button {
    width: 200px;
    margin-top: 20px; } }

@media (max-width: 575px) {
  .gallery-thumbnails {
    grid-template-columns: repeat(2, 1fr); }
  .container__articles img, .container__news img {
    float: none !important;
    display: block;
    margin-bottom: 10px; } }

@media (max-width: 480px) {
  .adr__left {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px; } }

#callbackModal .terms_of_use {
  padding: 0 20px 20px;
  font-size: 12px; }

#callbackModal label {
  display: none; }

#callbackModal input {
  border: 1px solid #ececec;
  height: 45px;
  box-shadow: none; }
  #callbackModal input:focus {
    border: 1px solid #ccc; }

.redButton {
  border: none;
  width: 200px;
  margin-top: 20px; }

.faq {
  margin-top: 50px;
  margin-bottom: 50px; }
  .faq-item {
    border-top: 1px solid #d9dde8;
    padding: 24px 20px; }
    .faq-item:last-child {
      border-bottom: 1px solid #d9dde8; }
    .faq-item.active .faq-question::after {
      transform: rotate(0); }
  .faq-link {
    font-size: 18px;
    color: #0038ff;
    border-bottom: 1px solid rgba(0, 56, 255, 0);
    transition: 0.3s; }
    .faq-link:hover {
      color: #0038ff;
      border-color: #0038ff; }
  .faq-question {
    font-size: 20px;
    color: #212121;
    cursor: pointer;
    position: relative;
    padding-right: 20px; }
    .faq-question::after {
      content: '';
      position: absolute;
      right: 0;
      top: 6px;
      width: 15px;
      height: 15px;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.34843' y1='1.56612' x2='14.4288' y2='14.6465' stroke='black'/%3E%3Cline x1='14.3628' y1='1.28251' x2='1.28248' y2='14.3629' stroke='black'/%3E%3C/svg%3E%0A");
      transform: rotate(-135deg);
      transition: 0.3s; }
  .faq-anwser {
    color: #757575;
    display: none;
    padding-top: 12px; }

.faq {
  margin-top: 50px;
  margin-bottom: 50px; }
  .faq-item {
    border-top: 1px solid #d9dde8;
    padding: 24px 20px; }
    .faq-item:last-child {
      border-bottom: 1px solid #d9dde8; }
    .faq-item.active .faq-question::after {
      transform: rotate(0); }
  .faq-link {
    font-size: 18px;
    color: #0038ff;
    border-bottom: 1px solid rgba(0, 56, 255, 0);
    transition: 0.3s; }
    .faq-link:hover {
      color: #0038ff;
      border-color: #0038ff; }
  .faq-question {
    font-size: 20px;
    color: #212121;
    cursor: pointer;
    position: relative;
    padding-right: 20px; }
    .faq-question::after {
      content: '';
      position: absolute;
      right: 0;
      top: 6px;
      width: 15px;
      height: 15px;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.34843' y1='1.56612' x2='14.4288' y2='14.6465' stroke='black'/%3E%3Cline x1='14.3628' y1='1.28251' x2='1.28248' y2='14.3629' stroke='black'/%3E%3C/svg%3E%0A");
      transform: rotate(-135deg);
      transition: 0.3s; }
  .faq-anwser {
    color: #757575;
    display: none;
    padding-top: 12px; }

.ym__name {
  max-width: 276px;
  line-height: 1;
  display: block;
  color: #0038ff !important;
  font-family: EB;
  margin-top: 10px; }

.ym__mode {
  display: block;
  margin-top: 5px; }

.sp__title {
  height: 40px;
  width: 200px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 5px; }

.container-map {
  margin-top: 40px;
  margin-bottom: 40px; }

.list_projects .items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px; }

.list_projects .pm__img.project-pm-img {
  padding: 5px; }

.list_projects .pm__img.news__pm__img {
  padding: 10px; }

.list_projects .pm__img img {
  width: 100%; }

.pm__date {
  font-size: 14px;
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 10px; }

.pm__name {
  font-family: EB;
  font-size: 18px;
  line-height: 1; }

.pm__link {
  margin-left: auto;
  height: 45px;
  width: 120px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333 !important;
  transition: all .4s; }
  .pm__link:hover {
    border: 1px solid #333;
    color: #212121; }

.pm__short {
  margin-top: 15px; }

.pm__footer {
  margin-top: auto;
  padding: 10px; }

.pm__info {
  padding: 0 10px; }

.project-map-item {
  background: linear-gradient(0deg, #ebf1f2, #ebf1f2), #f5f3ec;
  padding-bottom: 15px;
  display: flex;
  flex-flow: column; }
  .project-map-item.pm__news {
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

.projects-list {
  margin-top: 30px; }
  .projects-list .summary {
    margin-bottom: 30px;
    opacity: 0.6; }

#map {
  border-top: 2px solid #0038ff; }

.project-photos-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-top: 30px;
  margin-bottom: 30px; }

.project-thumbnail {
  padding: 5px;
  border: 1px solid #ececec; }

.project__single-content {
  margin-bottom: 50px; }

@media (max-width: 1000px) {
  .pm__short {
    font-size: 14px;
    margin-top: 10px; }
  .pm__name {
    font-size: 16px; }
  .pm__date {
    font-size: 12px; }
  .pm__link {
    font-size: 14px; }
  .list_projects .items {
    grid-gap: 10px; } }

@media (max-width: 767px) {
  .list_projects .items {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 480px) {
  .list_projects .items {
    grid-template-columns: 1fr; } }
