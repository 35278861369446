.site-contacts{
    height: 535px;
    }
.contacts-btn{
    width: 150px;
    margin-top: 25px;
    }
.contacts-title{
    margin-top: 5px;
    margin-bottom: 15px;
    }
.contacts__item{
    height: 100%;
    width: 50%;
    &.c-left{
        z-index: -1;
        top: 0;
        left: 0;
        background:#fff;

        }
    &.c-right{
        top: 0;
        right: 0;
        background:yellow;
        display: flex;
        align-items: stretch;


            iframe{
                width: 100%!important;
                height: 100%!important;
                border-right: 0!important;
                // filter: hue-rotate(270deg);
            }

        }
    }
.contacts__info{
    padding-top: 70px;
    }
.site-mode{
    color: #7A8086;
    }
.adr-head{
    color: #7A8086;
    margin-top: 15px;
    }
.contacts-phone{
    font-family: RB;
    font-size: 24px;
    color: #0038FF;
    }
.site-address{
    font-size: 20px;
    font-family: RB;
    color: #454545;
    }
@media (max-width: 1180px) {
    .contacts__item{
        &.c-right{
            position: relative;
            width: 100%;
            height: 535px;
        }
    }
    .site-contacts{
        height: auto;
    }
    .contacts__info{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .contacts-title{
        br{
            &:last-child{
                display: none;
            }
        }
    }
}
@media (max-width: 450px) {
    .contacts-title{
        br{
            display: none;
        }
    }
    .site-address{
        font-size: 1rem;
    }
}