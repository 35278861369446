.services-box{
	display: grid;
	grid-template-columns: repeat(3,1fr);
	grid-gap:20px;
	margin-top: 30px;
}

.service__img{
position: relative;
overflow: hidden;
&::before{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(black,.6);
	transition: all .4s;
	}
	img{
		width: 100%;
	}
	&:hover{
		&::before{
			opacity: 0;
		}
		.service__name{
			top: 0;
			left: 0;
			background: #EBF1F2;
			padding: 15px;
			width: 100%;
			color: #212121;
			box-shadow: 0 0 15px rgba(black,.4);
		}
	}
}
.service__name{
	color: #fff;
	top: 15px;
	left: 15px;
	width: 90%;
	// transition: all .1s;
}

.service__item{
	background: #EBF1F2;
	font-family: EB;
	color: #212121!important;
}

.service__info{
	padding: 15px 10px;
	line-height: 1.2;
	min-height: 87px;
	display: flex;
	align-items: center;
	}
.btn__service{
	color: #212121;
	span{
		border-bottom: 1px solid #212121;
	}
}