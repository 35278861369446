.pagecontent__sidebar{
	width: 300px;
	border: 1px solid #ececec;
	padding: 0 0 20px 0;
}
.pagecontent__left{
	width: calc(100% - 330px);
}
.page__content__flex{
	justify-content: space-between;
}
.child__link{
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
	align-items: center;
	min-height: 50px;
	border-bottom: 1px solid #ececec;
	color: #0038ff!important;
	span{
		border-bottom: 1px solid #0038ff;
	}
}

.gallery-thumbnails{
	overflow: hidden;
	display: grid;
	grid-template-columns: repeat(4,1fr);
	grid-gap:15px;
}
.thumbnail{
	margin-bottom: 0;
	cursor: pointer;
}
.gallery-thumbnail{
	height: auto;
}

.gallery-show__header{
	padding-bottom: 20px;
	margin-bottom: 30px;
	border-bottom: 1px solid #ececec;
}

.fields{
	display: grid;
	grid-template-columns: repeat(2,1fr);
	grid-column-gap: 20px;
	margin-bottom: 20px;
	.form-group{
		label{
			display: none;
		}
	}
	.textarea{
	display: flex;
		.form-group{
		    width: 100%;
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;
		}
		textarea{
			box-shadow: none!important;
			border-radius: 0;
		}
	}
	.inputs-wrap{
		.form-group{
		margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		input{
			height: 40px;
			border-radius: 0;
			box-shadow: none!important;
		}
	}
}
#politic{
	display: flex;
	line-height: 1;
	font-size: 14px;
	align-items: center;
	a{
		color: #333!important;
		span{
			border-bottom: 1px solid #0038ff;
			color: #0038ff!important;
		}
	}
	input{
		display: none;
		&:checked+label{
			background: #0038ff url('../images/svg/check.svg') no-repeat center center;
		}
	}
	label{
		width: 20px;
		height: 20px;
		position: relative;
		border: 1px solid #ccc;
		border-radius: 5px;
		margin-right: 5px;
		cursor: pointer;
	}
}
.sender_box{
	display: flex;
	width: calc(50% - 10px);
	justify-content: space-between;
	margin-top: 15px;
}
#form-button{
border: none;
border-radius: 50px;
height: 45px;
background: #0038ff;
padding-left: 20px;
padding-right: 20px;
color: #fff;
	&.disabled{
		pointer-events: none;
		opacity: 0.5;
	}
}
#about-form{
	margin-bottom: 50px;
	margin-top: 50px;
}
.error{
	color: #a94442;
	font-size: 14px;
	width: 100%;
}

.form-control{
	&.error{
		border: 1px solid #a94442!important;
	}
}

.requsits-grid{
	display: grid;
	grid-template-columns: repeat(2,1fr);
	border: 1px solid #ececec;
	margin-top: 30px;
}
.r-item{
	padding: 10px;
	color: 333;
	padding-right: 50px;
}
.r-item__name{
	font-family: RB;
	font-size: 18px;
	margin-bottom: 5px;
}
.r-item__desc{
	opacity: 0.7;
	ul{
		padding-left: 0;
		margin-bottom: 0;
	}
}
.doc-link{
	padding: 15px 0;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #ececec;
	color: #0038ff;
	svg{
		margin-left: 15px;
	}
}

.textContentStance{
	margin-top: 30px;
	margin-bottom: 30px;
}
.tableContentStance{
	p{
	margin-bottom: 0;
	}
	table{
	width: 100%;
	tr{
		&:first-child{
			td{
				font-family: EB;
				}
			}
		}
	td{
		border: 1px solid #ccc;
		padding: 15px 10px;
		.td-before{
			display: none;
			}
		}
	}
}
// .td-value,.td-before {
//     white-space: break-spaces;
// }
.page-site-stance{
	padding-bottom: 50px;
}

 .content-site{
	margin-bottom: 80px;
}
.contacts-map{
	margin-top: 30px;
	border-top: 2px solid #0038ff;
}
.adr__left{
	width: 250px;
	margin-right: 30px;
}
.adr__tr{
	padding: 15px 0;
}

@media (max-width:1200px) {
	.sender_box{
		width: 100%;
	}
}

@media (max-width:960px) {
	.requsits-grid{
		grid-template-columns: 1fr;
	}
	.r-item__desc{
		ul{
			list-style: none;
		}
	}
}
@media (max-width:900px) {
	.pagecontent__left{
		width: 100%;
		}
	.pagecontent__sidebar{
		width: 100%;
		order:-1;
		margin-bottom: 30px;
	}
	.childBox{
		&:last-child{
			a{
				border-bottom: none;
			}
		}
	}
}
@media (max-width:767px) {
	.fields{
		grid-template-columns: 1fr;
		grid-gap:20px;
		.textarea{
			height: 150px;
		}
	}
	.gallery-thumbnails{
		grid-template-columns: repeat(3,1fr);
		grid-gap:10px;
	}
}
@media (max-width:640px) {
	.tableContentStance table{
		td,tr{
			display: block;
		}
		tr{
			&:first-child{
				display: none;
			}
		}
		tr{
			margin-bottom: 10px;
		}
	}
	.td-before{
		display: block!important;
		font-family: EB;
		font-size: 12px;
	}
}
@media (max-width:584px) {
	.sender_box{
		flex-flow: column;
	}
	#form-button{
		width: 200px;
		margin-top: 20px;
	}
}
@media (max-width:575px) {
	.gallery-thumbnails{
		grid-template-columns: repeat(2,1fr);
	}
	.container__articles,.container__news{
		img{
			float: none!important;
			display: block;
			margin-bottom: 10px;
		}

	}
}

 @media (max-width:480px) {
        .adr__left{
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }