.ym__name{
	max-width: 276px;
	line-height: 1;
	display: block;
	color: #0038ff!important;
	font-family: EB;
	margin-top: 10px;
}
.ym__mode{
display: block;
margin-top: 5px;
}
.sp__title{
	height: 40px;
	width: 200px;
	border: 1px solid #ccc;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-left: 5px;
}

.container-map{
	margin-top: 40px;
	margin-bottom: 40px;
}

.list_projects{
	.items{
		display: grid;
		grid-template-columns: repeat(3,1fr);
		grid-gap:20px;
	}
	.pm__img{
	&.project-pm-img{
		padding: 5px;
	}
	&.news__pm__img{
		padding: 10px;
	}
		img{
			width: 100%;
		}
	}
}
.pm__date{
	font-size: 14px;
	opacity: 0.7;
	margin-top: 10px;
	margin-bottom: 10px;
}

.pm__name{
	font-family: EB;
	font-size: 18px;
	line-height: 1;
}

.pm__link{
	margin-left: auto;
	height: 45px;
	width: 120px;
	border: 1px solid #ccc;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #333!important;
	transition: all .4s;
	&:hover{
		border: 1px solid #333;
		color: #212121;
	}
}

.pm__short{

margin-top: 15px;}

.pm__footer{
	margin-top: auto;
	padding: 10px;
}

.pm__info{
padding: 0 10px;
// margin-top: auto;
}

.project-map-item{
	background: linear-gradient(0deg,#ebf1f2,#ebf1f2),#f5f3ec;
	padding-bottom: 15px;
	display: flex;
	flex-flow: column;
	&.pm__news{
		background: #fff;
		box-shadow: 0 0 15px rgba(black,.2);
		}
	};

.projects-list{
	margin-top: 30px;
	.summary{
		margin-bottom: 30px;
		opacity: 0.6;
	}
}

#map{
	border-top: 2px solid #0038ff;
}

.project-photos-box{
	display: grid;
	grid-template-columns: repeat(3,1fr);
	grid-gap:15px;
	margin-top: 30px;
	margin-bottom: 30px;
}
.project-thumbnail{
	padding: 5px;
	border: 1px solid #ececec;
}

.project__single-content{
	margin-bottom: 50px;
}

@media (max-width:1000px) {
.pm__short{
	font-size: 14px;
	margin-top: 10px;
}
	.pm__name{
		font-size: 16px;
		}
	.pm__date{
		font-size: 12px;
	}
	.pm__link{
		font-size: 14px;
	}
	.list_projects .items{
		grid-gap:10px;
	}
}
@media (max-width:767px) {

	.list_projects .items{
		grid-template-columns: repeat(2,1fr);
	}
}
@media (max-width:480px) {

	.list_projects .items{
		grid-template-columns: 1fr;
	}
}