.view-categoty-flex{
    justify-content: space-between;
    padding-top: 20px;
    .slick-dots{
        display: flex;
        justify-content: center;
        width: 100%;
        li{
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background: #C4C4C4;
            &.slick-active{
            background: #0038FF;
            }
        }
    }
}
.vcf__info{
    width: calc(100% - 787px);
    padding-top: 10px;
}
.vcf__image{
    background: #EBF1F2;
    width: 645px;
}
.vcf__image_item{
    height: 460px;
    // height: 100%;
}

.vcf__image__thumb{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.bordered{
    background: #fff;
    border: 1px solid #ececec;
    }
}

.vcf__short{
    color:#454545;
    margin-top: 20px;
    font-family: RR;
}
.calculation{
    width: 195px;
    margin-right: 30px;
}
.questionnaire{
    width: 195px;
}
.vcf__buttons{
    margin-top: 29px;
}
.sigle-category-description{
    margin-top: 80px;
    width: calc(100% - 645px);
    color:#454545;
    table{
        width: 100%;
        font-family: RR;
        tr{
            border-bottom: 2px solid #E9EAF3;
            td{
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
}

.single-nav{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-top: 74px;
    font-size: 20px;
     border-bottom: 1px solid #D9DDE8;
    li{
        padding-bottom: 16px;
        margin-right: 60px;
        position: relative;
        cursor:pointer;
        &::before{
            content:"";
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 2px;
            background: transparent;
            transition:all .4s;
        }
        &:last-child{
            margin-right: 0;
        }
        &.active{
            color:#0038FF;
            &::before{
                background: #0038FF;
            }
        }
    }
}

.tr-header{
    display: flex;
    font-size: 18px;
    width: 100%;
    justify-content: space-between;

    border-bottom: 1px solid #D9DDE8;
    .tr-product__item{
        color: #212121;
    }
}
.data-product{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D9DDE8;
}
.td-name{
    color: #333;
    margin-bottom: 10px;
    max-width: 160px;
    font-size: 14px;
    opacity: 0.7;
}
.td-name-product{
    margin-bottom: 10px;
    font-size: 14px;
}
.td-main{
color: #212121;
}
.tr-product__item{
    line-height: 1;
    padding-bottom: 30px;
    padding-top: 30px;
    font-family: ER;
    color:#757575;
    a{
        text-decoration: underline!important;
        font-family: EB;
    }
    &.last-of-type{
        margin-right: 0;
    }
}
.tabPane{
    position: absolute;
    z-index: -1;
    opacity: 0;
    // min-height: 460px;
    &.active{
        position: relative;
        z-index: 10;
        opacity: 1;
    }
}
#product-box{
    .page{
        a{
            color:#0038FF;

        }
        &.active{
            a{
               background: #0038FF;
               border-color: #0038FF;
               color:#fff;
            }
        }
    }
    li{
        i{
           color:#0038FF;
        }
        &.disabled{
            i{
                color:#ccc;
            }
        }
    }
}
.equipment__table{
    table{
        border:none!important;
        td{
            border: none;
            border-right: 1px solid #D9DDE8;
            padding: 34px 0;
            vertical-align: top;
            padding-left: 20px;
            padding-right: 15px;
            &:last-child{
                border-right: 0;
            }
            &:first-child{
                padding-left: 0;
            }
        }
        tr{
            border-bottom: 1px solid #D9DDE8;
            color:#757575;
            &:first-child{
                color:#212121;
                font-size: 18px;
                line-height: 1.2;
            }
        }
    }
}
.work_principle__table{
    table{
        border: none!important;
        td{
            border: none;
            padding: 20px 0;
        }
        tr{
            border-bottom: 1px solid #D9DDE8;
        }
    }
}
.versions__table{
    table{
        border: none!important;
        table-layout: fixed;
        tr{
            border-bottom: 1px solid #D9DDE8;
            display: flex;
        }
        td{
            width: auto!important;
            border: none;
            padding: 34px 0;

            &:first-child{
                width: 55%!important;
                padding-right: 50px;
            }
            &:last-child{
                width: 45%!important;
            }
            img{
                max-width: 100%!important;
            }
            &:last-child{
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

 .doc-link__wrap{
     width: 100%;
     h2{
        font-family: EB;
        color:#494949;
     }
 }

.filesize-box{
    color: #454545;
    line-height: 1;
}
.linksWrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:30px;

}
.li_wrap{
    p{
        position: relative;
        padding-left: 35px;
        &::before{
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 40px;
        }
        &.pdf-box{
           &::before{
             background: url('../images/svg/pdf.svg') no-repeat center center;
            background-size: 30px 40px;
           }
        }
        &.word-box{
            &::before{
            background: url('../images/svg/docx.svg') no-repeat center center;
            background-size: 30px 40px;
            }
        }
        a{
            color:#0038FF;
        }
    }
}
.doc-link__wrap{
    h2{
        width: 100%;
    }
    .linksWrapper{
        width: 100%;
    }
}
.pdf-container{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
}

@media (max-width: 1250px) {
    .single-nav{
        font-size: 1rem;
        li{
            padding-bottom: 5px;
        }
    }
    .vcf__info{
        width: calc(50% - 30px);
    }
    .vcf__image{
        width: 50%;
    }
}
@media (max-width: 1200px) {
    .tr-header{
         .tr-product__item{
            font-size: 14px;
         }
    }
    .data-product{
        font-size: 14px;
    }
}
@media (max-width: 1050px) {
    .sigle-category-description{
        width: 100%;
         table{
             tr{
                border: none;
                 td{
                    border:1px solid #ededed;
                    padding: 10px 5px;
                 }
             }
         }
    }
    .tr-product__item{
        width: 100%;
        padding: 15px 10px;
        position: relative;
        color: #212121;
        border: 1px solid #ccc;
        margin-top: -1px;
        margin-left: -1px;
        // &::before{
        //     content: attr(data-head);
        //     display: flex;
        //     flex-flow: column;
        //     color: #454545;
        //     opacity: 0.6;
        //     margin-bottom: 5px;
        // }
    }
    .product-list{
        display: grid;
        grid-template-columns: repeat(4,1fr);
    }
    .data-product{
        flex-flow: column;
        align-items: flex-start;
        padding: 15px 0;
        padding-left: 1px;
    }
    .tr-header{
        display: none;
    }
    .vcf__info{
        width: 100%;
        order: 2;
    }
    .vcf__image{
        width: 645px;
    }
}
@media (max-width: 1010px) {
    .linksWrapper{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 20px;
    }
    .single-nav{
        li{
            margin-right: 20px;
        }
    }
}

@media (max-width: 991px) {
    .versions__table{
         table{
            td{
                &:first-child{
                    width: 100%!important;
                    justify-content: flex-start;
                }
                &:last-child{
                    order: -1;
                    padding-bottom: 0;
                    width: 100%!important;
                    justify-content: flex-start;
                }
            }
             tr{
                flex-flow: column;
             }
         }
    }
}

@media (max-width: 850px) {
    .equipment__table{
         table{
            tr{
                font-size: 14px!important;
            }
             td{
            padding: 15px 10px;
            }


         }
    }
     .product-list{
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width: 810px) {
    .pojects-section{
        &.projects-on-category{
            margin-bottom: 60px;
        }
    }
    .single-nav{
        flex-flow: column;
        margin-top: 0;
        li{
            margin-right: 0;
            padding: 10px;
            background: #ededed;
            margin-bottom: 5px;
            &.active{
                background: #0038ff;
                color: #fff;
                &::before{
                    display: none;
                }
            }
        }
    }
}
@media (max-width: 675px) {

    .vcf__image{
        width: 100%;
    }
}
@media (max-width: 640px) {
    .pdf-container{
        grid-template-columns: 1fr;
        max-height: 440px;
        overflow: hidden;
        overflow-y: auto;
        border-bottom: 1px solid #dedede;
        padding-bottom: 10px;
    }
    .view-categoty-flex{
         .slick-dots{
            bottom: -20px;
         }
    }

    .equipment__table{
         table{
            tr{
                display: block;
                margin-bottom: 10px;
                width: 100%;
                border: none;
                &:first-child{
                    display: none;
                }
                td{
                    border: 1px solid #dedede!important;
                    padding: 15px 10px!important;
                    display: flex;
                    flex-flow: column;
                    width: 100%!important;
                    border: none;
                    position: relative;
                    font-size: 1rem;
                    &.emptyCeil{
                        display: none;
                    }
                    &::before{
                        content: attr(data-head);
                        width: 100%;
                        font-family: EB;
                        color: #212121;
                        font-size: 14px;
                    }
                }
            }
         }
    }

}
@media (max-width: 696px) {
    .linksWrapper{
        grid-template-columns: 1fr;
        grid-gap: 15px;
    }
}

@media (max-width: 450px) {
    @media (max-width: 850px) {
     .product-list{
        grid-template-columns: repeat(1,1fr);
    }
}
    .vcf__image__thumb{
        img{
            width: 100%;
        }
    }
    .vcf__buttons{
        flex-flow: column;
    }
    .calculation{
        margin-right: 0;
        margin-bottom: 15px;
    }
    .vcf__image_item{
        height: auto;
        padding: 30px 0;
    }
}

@media (max-width: 400px) {
    .versions__table{
         table{
             td{
                font-size: 14px;
                 img{
                    width: 40%;
                    height: auto;
                 }
                 &.single-td{
                        img{
                            width: 100%;
                        }
                    }
             }
         }
    }

}