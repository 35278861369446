@mixin fontface($name_font, $src) {
  @font-face {
      font-family: quote($name_font);
      src: url(quote("../fonts/" + $name_font + ".eot"));
      src: url(quote("../fonts/" + $src + ".eot?#iefix")) format("embedded-opentype"),
      url(quote("../fonts/" + $src + ".woff")) format("woff"),
      url(quote("../fonts/" + $src + ".ttf")) format("truetype");
  }
}

@include fontface("ER", "ElektraTextPro");
@include fontface("EB", "ElektraTextPro-Bold");
@include fontface("RR", "Roboto-Regular");
@include fontface("RB", "Roboto-Bold");
@include fontface("RBI", "Roboto-BoldItalic");

a{
  text-decoration: none!important;
  &:focus{
    outline: none;
  }
}
.wrapper {
  max-width: 100%;
  min-width: 260px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  top: 104px;
  overflow: hidden;

  .content{
    flex: 1 0 auto;
  }
  .footer {
    flex: 0 0 auto;
    }
}
  html,body{
    height: 100%;
    font-family: RR;
    font-size: 16px;
    color:#212121;
  }

.container {
        padding: 0;
        width: 1320px;
        @media (max-width: 1366px) {
            width: 100%;
            padding: 0 15px;
            }
        &::before{
         display: none;
         }
        &::after{
         display: none;
         }
    }
  .d-flex{
     display: flex;
     flex-wrap: wrap;
     }
.posrel{
    position: relative;
    }
.abs{
    position: absolute;
    }
.page-title{
    font-size: 32px;
    font-family: ER;
    color: #212121;
    @media (max-width: 750px) {
      font-size: 25px;
    }
    }
  .product-title{
    @extend .page-title;
    font-size: 40px;
    @media (max-width: 1250px) {
      font-size: 32px;
    }
    @media (max-width: 500px) {
      font-size: 24px;
    }
    }
.slide-title{
    @extend .page-title;
    font-size: 45px;
    }
.ttu{
    text-transform: uppercase;
    }
.db{
    display: block;
}
.bdr{
    border-radius: 50%;
}

.posfix{
    position: fixed;
    }
  .list-style-none{
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
.btn-blue{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0038FF;
  border-radius: 50px;
  color: #fff!important;
  font-size: 18px;
  height: 45px;
  }
.btn-prod{
  @extend .btn-blue;
  justify-content: flex-start;
  padding-left: 34px;
  padding-right: 15px;
  svg{
    margin-left: 19px;
    }
  }
.btn-bordered{
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid #212121;
  box-sizing: border-box;
  border-radius: 50px;
  color: #212121!important;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  }

  /***/
  .breadcrumb{
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    padding-top: 26px;
    display: flex;
    flex-wrap: wrap;
    a{
      color:#757575;
      }
    li{
      &:last-child{
        a{
          pointer-events: none;
          cursor:default;
        }
      }
      @media (max-width: 991px) {
        display: flex;
        &.active{
          max-width: 138px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
        a{
          max-width: 138px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
    }
    .breadcrumb>li+li:before{
          padding: 0 5px 0 10px;
          @media (max-width: 991px) {
            content:"\2022";
            color:#212121;
          }
      }


      .list-none{
        list-style-type: none;
        padding-left: 0;
      }
 /****************************/
           .preloader{
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#circularG{
  position:relative;
  width:68px;
  height:68px;
  margin: 15px 0px 0;

}

@media (max-width: 480px){
  .preloader {
    img{
      max-width: 200px;
    }
  }
}

.circularG{
  position:absolute;
  background-color:#0038FF;
  width:16px;
  height:16px;
  border-radius:10px;
    -o-border-radius:10px;
    -ms-border-radius:10px;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
  animation-name:bounce_circularG;
    -o-animation-name:bounce_circularG;
    -ms-animation-name:bounce_circularG;
    -webkit-animation-name:bounce_circularG;
    -moz-animation-name:bounce_circularG;
  animation-duration:1.1s;
    -o-animation-duration:1.1s;
    -ms-animation-duration:1.1s;
    -webkit-animation-duration:1.1s;
    -moz-animation-duration:1.1s;
  animation-iteration-count:infinite;
    -o-animation-iteration-count:infinite;
    -ms-animation-iteration-count:infinite;
    -webkit-animation-iteration-count:infinite;
    -moz-animation-iteration-count:infinite;
  animation-direction:normal;
    -o-animation-direction:normal;
    -ms-animation-direction:normal;
    -webkit-animation-direction:normal;
    -moz-animation-direction:normal;
}

#circularG_1{
  left:0;
  top:27px;
  animation-delay:0.41s;
    -o-animation-delay:0.41s;
    -ms-animation-delay:0.41s;
    -webkit-animation-delay:0.41s;
    -moz-animation-delay:0.41s;
}

#circularG_2{
  left:7px;
  top:7px;
  animation-delay:0.55s;
    -o-animation-delay:0.55s;
    -ms-animation-delay:0.55s;
    -webkit-animation-delay:0.55s;
    -moz-animation-delay:0.55s;
}

#circularG_3{
  top:0;
  left:27px;
  animation-delay:0.69s;
    -o-animation-delay:0.69s;
    -ms-animation-delay:0.69s;
    -webkit-animation-delay:0.69s;
    -moz-animation-delay:0.69s;
}

#circularG_4{
  right:7px;
  top:7px;
  animation-delay:0.83s;
    -o-animation-delay:0.83s;
    -ms-animation-delay:0.83s;
    -webkit-animation-delay:0.83s;
    -moz-animation-delay:0.83s;
}

#circularG_5{
  right:0;
  top:27px;
  animation-delay:0.97s;
    -o-animation-delay:0.97s;
    -ms-animation-delay:0.97s;
    -webkit-animation-delay:0.97s;
    -moz-animation-delay:0.97s;
}

#circularG_6{
  right:7px;
  bottom:7px;
  animation-delay:1.1s;
    -o-animation-delay:1.1s;
    -ms-animation-delay:1.1s;
    -webkit-animation-delay:1.1s;
    -moz-animation-delay:1.1s;
}

#circularG_7{
  left:27px;
  bottom:0;
  animation-delay:1.24s;
    -o-animation-delay:1.24s;
    -ms-animation-delay:1.24s;
    -webkit-animation-delay:1.24s;
    -moz-animation-delay:1.24s;
}

#circularG_8{
  left:7px;
  bottom:7px;
  animation-delay:1.38s;
    -o-animation-delay:1.38s;
    -ms-animation-delay:1.38s;
    -webkit-animation-delay:1.38s;
    -moz-animation-delay:1.38s;
}



@keyframes bounce_circularG{
  0%{
    transform:scale(1);
  }

  100%{
    transform:scale(.3);
  }
}

@-o-keyframes bounce_circularG{
  0%{
    -o-transform:scale(1);
  }

  100%{
    -o-transform:scale(.3);
  }
}

@-ms-keyframes bounce_circularG{
  0%{
    -ms-transform:scale(1);
  }

  100%{
    -ms-transform:scale(.3);
  }
}

@-webkit-keyframes bounce_circularG{
  0%{
    -webkit-transform:scale(1);
  }

  100%{
    -webkit-transform:scale(.3);
  }
}

@-moz-keyframes bounce_circularG{
  0%{
    -moz-transform:scale(1);
  }

  100%{
    -moz-transform:scale(.3);
  }
}
