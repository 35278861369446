#callbackModal{

	.terms_of_use{
		padding: 0 20px 20px;
		font-size: 12px;
	}

	label{
		display: none;
	}
	input{
		border: 1px solid #ececec;
		height: 45px;
		box-shadow: none;
		&:focus{
			border: 1px solid #ccc;
		}
	}
}
.redButton{
	@extend .btn-blue;
	border: none;
	width: 200px;
	margin-top: 20px;
}