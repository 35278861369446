header{
    width: 100%;
    height: 104px;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, #EBF1F2, #EBF1F2), #F5F3EC;
    position: fixed;
    z-index: 900;
    &.scrolled{
        box-shadow: 0 2px 15px rgba(black,.2);
        }
    }
.container-header{
    align-items: center;
    height: 100%;
    }
.logo-site{
    width: 196px;
    margin-right: 30px;
    }
.logo__description{
    top: -10px;
    left: 50px;
    line-height: .9;
    font-family:RBI;
    font-size: 25px;
    span{
        color: #89C645;
        &:first-child{
            color: #004D94;
            }
        }
    }
.menus{
    display: flex;
    flex-direction: column;
    width: calc(100% - 226px);
    }
.phone-with-mode{
    align-items: flex-end;
    margin-right: auto;
    }
.first-menu{
    align-items: flex-end;
    line-height: 1;
    margin-bottom: 20px;
    }
.top-menu{
    width: calc(100% - 314px - 180px);
    justify-content: space-between;
    font-size: 14px;
    a{
        color: #454545;
        }
    }

.header-phone{
    color: #0038FF!important;
    font-family: EB;
    margin-right: 18px;
    }
.company-work-mode{
    font-size: 14px;
    font-family: ER;
    color: #757575;
    opacity: 0;
    z-index: -1;
    }
.catalog-header{
    justify-content: space-between;
    font-size: 17px;
    li{
        position: relative;
        .subspan{
            width: 9px;
            height: 5px;
            position: absolute;
            top: 55%;
            left: calc(100% + 5px);
            transform:translate(0,-50%);
            width: 9px;
            height: 5px;
            background:url('../images/svg/cm.svg') no-repeat 0 0;
            background-size: 100%;
            }
        .sub-menu{
            position: absolute;
            display: none;
            border: 1px solid #0038ff;
            top: 100%;
            left: 0;
            list-style-type: none;
            padding: 0!important;
            z-index: 200;
            background:#fff;
            padding: 10px 20px 10px 15px;
            line-height: 2;
            box-shadow: 0 0 15px rgba(black,.2);
            width: 300px;
            white-space: normal;
            line-height: 1;
            li{
                border-bottom: 1px solid rgba(#ccc,.5);
                padding: 10px 15px;
                &:hover{
                    background: #EBF1F2;
                }
                &.active{
                    background: #f8f8f8;
                }
                .subspan{
                    background:url('../images/svg/sub.svg') no-repeat 0 0;
                    background-size: 100%;
                    width: 6px;
                    height: 10px;
                    }
                &:last-child{
                    border-bottom: 0;
                }
            }
            ul{
                display: block;
                top: 0;
                padding-right: 15px;
                left: calc(100% + 20px);
                box-shadow: none;
                font-size: 14px;
                display: none;
                a{
                    color: #757575;
                    }
                &::before,&::after{
                    display: none;
                    }
            }
        }
    }
    a{
        color: #212121;
        display: block;
        }
    }
    .toggler,.pwm-mobile,.close-button{
        display: none;
    }
    /*MEDIA*/

    @media (max-width: 1295px) {
        .logo-site{
            margin-right: 0;
        }
        .toggler{
            display: block;
            width: 40px;
            height: 30px;
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            align-items: center;
            // margin-left: auto;
            span{
                width: 35px;
                height: 4px;
                background: #0038ff;
                display: block;
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: #0038ff;
                    left: 0;
                    top: -8px;
                    transition: all .4s;
                }
                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: #0038ff;
                    left: 0;
                    bottom: -8px;
                    transition: all .4s;
                }
                &.active{
                    background: transparent;
                    &::before{
                        top: 0;
                        transform: rotate(-135deg);
                    }
                    &::after{
                        bottom: 0;
                        transform: rotate(135deg);
                    }
                }
            }
        }
        .container-header{
            // justify-content: space-between;
        }
        .menus{
            padding-top: 52px;
            position: fixed;
            width: 400px;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            left: 0;
            top: 0;
            left: -500px;
            z-index: 1000;
            background: #fff;
            align-items: center;
            box-shadow: inset 0 0 400px rgba(#0038ff,.2),0 0 20px rgba(#000,.2);
            transition: all .4s;
            &.active{
                left: 0;
            }
        }
        .first-menu{
            width: 280px;
        }
        .top-menu{
            font-size: 1rem;
            width: 100%;
            flex-flow: column;
            line-height: 1.5;
        }
        .second-menu{
            width: 280px;
        }
        .catalog-header{
            flex-flow: column;
            line-height: 1.5;
            li{
                .subspan{
                    display: none!important;
                }
            }
        }
        .phone-with-mode{
            margin-right: 0;
            display: none;
        }
        .sub-menu{
            display: none!important;
        }
        .pwm-mobile{
            display: block;
            margin-left: auto;
            margin-right: 20px;
            &.hx-width{
                display: none;
            }
        }
        .pwm-phone{
            color: #0038ff!important;
            font-family: RB;
        }
        .pwm-work-mode{
            color: #454545;
            opacity: 0.7;
            display: none;
        }
    }

    @media (max-width: 767px) {
        .logo__description{
            font-size: 14px;
            top: -7px;
            left: 27px;
        }
        .logo-site{
            width: 150px;
            img{
                width: 50px;
            }
        }
        header{
            height: 60px;
        }
        .wrapper{
            top: 60px;
        }
        .pwm-phone{
            font-size: 14px;
        }
        .pwm-work-mode{
            font-size: 14px;
            line-height: 1;
        }
        .toggler{
            width: 30px;
            border: 1px solid rgba(#0038ff,.5);
            border-radius: 5px;
            span{
                width: 20px;
            }
        }
    }

    @media (max-width: 480px) {
        .close-button{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid #0038ff;
            position: absolute;
            top: 20px;
            right: 20px;
            span{
                width: 20px;
                height: 4px;
                position: relative;
                &::before{
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    transform: rotate(-135deg);
                    background: #0038ff
                }
                &::after{
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    transform: rotate(135deg);
                    background: #0038ff
                }
            }
        }
        .menus{
            width: 100%;
        }
    }
    @media (max-width: 380px) {
        .pwm-mobile{
            margin-left: 0;
            margin-right: 0;
            display: none;
            &.hx-width{
                display: block;
                width: 280px;
                margin-top: 15px;
            }
        }
        .toggler{
            margin-left: auto;
        }
    }