.prodaction__head{
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;
    }
.production__titles{
    h1,h2{
       margin-top: 4px;
       margin-bottom: 0;
        }
    }
.produce-for-you{
    color: #757575;
    }
.product-list-grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap:30px;
    }
.grid__item{
    display: flex;
    flex-direction: column;
    background: linear-gradient(0deg, #EBF1F2, #EBF1F2), #F5F3EC;
    height: 320px;
    padding: 20px 15px 31px 30px;
    @media (min-width: 640px) {
            &:hover{
               .circle-arrow{
                    opacity: 1;
                    }
            }
        }
    }
#item57{
    grid-column: span 2;
    }
.circle-arrow{
    margin-top: auto;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#0038FF;
    border-radius: 50%;
    transition: all .4s;
    opacity: 0;
    &.green{
        background: #72AD22;
        }
    }

.grid-image{
    bottom: 20px;
    right: 40px;
    }
.grid-desc{
    width: 262px;
    line-height: 1.2;
    color: #757575;
    font-family: RR;
    font-size: 1rem;
    }
.grid-name{
    font-family: ER;
    font-size: 18px;
    color: #212121;
    line-height: 1.2;
    margin-bottom: 5px;
    }
.grid__item-main{
    display: flex;
    align-items: center;
    justify-content: center;
    background:#0038FF;
    color:#fff!important;
    text-align: center;
    &.green{
        background: #72AD22;
        }
    }
.homecategories{
    margin-top: 50px;
    margin-bottom: 80px;
    }
.modular-solutions{
    justify-content: space-between;
    padding-right: 38px;
    }
.module__info{
    width: 45%;
    display: flex;
    flex-direction: column;
    a{
        width: 198px;
        padding-left: 32px;
        padding-right: 14px;
        margin-top: 25px;
        }
    }
.info__content{
    color: #454545;
    }
.modular-widget{
    margin-top: 50px;
    margin-bottom: 73px;
    }
.single-with-children{
    background: linear-gradient(0deg, #EEF2F6, #EEF2F6), #EEF2F6;
    padding-top: 65px;
    padding-bottom: 64px;
    }
.single-grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap:30px;
    }
.single__item{
    min-height: 320px;
    background:#fff;
    display: flex;
    flex-direction: column;
    padding: 30px;
    }
.single__thumnale{
    top: 0;
    right: 0;
    }
.single__name{
    width: 218px;
    font-family: ER;
    color:#212121!important;
    font-size: 30px;
    line-height: 1;
    margin-bottom: 15px;
    }
.child-link{
    color: #757575;
    line-height: 1.5;
    }
.parent-link{
    margin-top: auto;
    display: block;
    line-height: 1;
    span{
        color: #0038FF;
        border-bottom: 1px solid #0038ff;
        }
    }
.pojects-section{
    margin-top: 70px;
    &.projects-on-category{
        margin-bottom: 140px;
    }
    }
.project__info{
    width: 50%;
    display: flex;
    flex-direction: column;
    background:#fff;
    padding: 36px 130px 44px 39px;
    position: relative;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    &::before{
        content:"";
        position: absolute;
        width: 10px;
        height: calc(100% + 2px);
        top: -1px;
        left: 0;
        background: #0038FF;
        }
    }
.project__img{
    position: relative;
    width: 50%;
    img{
        width: 100%;
        }
    }
.project__name{
    font-size: 20px;
    line-height: 1.2;
    font-family: ER;
    }
.project__date{
    color: #757575;
    margin-bottom: 5px;
    }
.project__desc{
    color: #757575;
    margin-top: 10px;
    line-height: 1.2;
    }
.project__detail{
    display: flex;
    align-items: center;
    margin-top: auto;
    color: #0038FF;
    span{
        margin-right: 10px;
        }
    }
.projects-carousel{
    margin-bottom: 0!important;
    }
.project-dots{
    .slick-dots{
        position: relative;
        display: flex;
        justify-content: center;
        bottom: 0;
        margin-top: 20px;
        }
    li{
        width: 10px;
        height: 10px;
        background: #C4C4C4;
        border-radius: 50%;
        &.slick-active{
            background: #0038FF;
            }
        }
    }

    @media (max-width: 1350px) {
        .single__thumnale{
            display: flex;
            height: 100%;
            align-items: flex-end;
            justify-content: flex-end;
            img{
                width: 70%;
            }
        }
        #item57 {
            grid-column: span 1;
        }
        .product-list-grid{
            grid-template-columns: repeat(3,1fr);
        }
        .grid__item-main{
            height: 320px;
        }
    }
@media (max-width: 1170px) {
    .project__img{
        img{
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
}

    @media (max-width: 1114px) {
        .single__thumnale{
            display: none;
        }
        .module__img{
            width: 46%;
            display: flex;
            align-items: center;
            img{
                width: 100%;
            }
        }
    }
    @media (max-width: 1055px) {
        // #item57 {
        //     grid-column: span 2;
        // }
        .product-list-grid{
            grid-template-columns: repeat(2,1fr);
        }
        // .grid__item-main{
        //     height: 320px;
        // }
    }

    @media (max-width: 951px) {
        .project__info{
            padding: 40px 20px 40px 30px;
        }
        .module__img{
            width: 413px;
            img{
                width: 100%;
            }
        }
        .module__info{
            width: 100%;
            order: 2;
        }
    }
    @media (max-width: 750px) {
        .project__info{
            width: 100%;
            padding: 15px;
            &::before{
                display: none;
            }
        }
        .project__img{
            width: 100%;
            order: -1;
            img{
                position: static;
            }
        }
        .homecategories{
            margin-bottom: 50px;
        }
        .single__item{
            padding: 15px;
        }
        .single__name{
            font-size: 25px;
        }
        .single-grid{
            grid-template-columns: 1fr;
        }
        .produce-for-you{
            font-size: 14px;
        }
    }
    @media (max-width: 675px) {
        .product-list-grid{
            grid-template-columns: 1fr;
        }
        // .grid__item-main{
        //     height: 320px;
        // }
    }

    @media (max-width: 640px) {
        .prodaction__head{
            flex-flow: column;
            align-items: flex-start;
        }
        .btn-prod{
        font-size: 1rem;
        margin-top: 15px;
       }
    }

    @media (max-width: 390px) {
        .grid__item-main{
            height: auto;
            padding: 20px 0;
        }
    }
    @media (max-width: 350px) {
       .grid__item{
        padding: 15px;
       }

    }