.list__item{
	padding: 10px 0;
	border-bottom: 1px solid #ececec;
	cursor: pointer;
	span{
		border-bottom: 1px solid #212121;
	}
}

#serviceModal {
	.modal-dialog{
		width: 1000px;
	}
	.smc__img{
	margin-bottom: 15px;
	}
}

@media (max-width:1030px) {
	#serviceModal {
	.modal-dialog{
		width: calc(100% - 30px);
	}

	}
}
@media (max-width:767px) {
	#serviceModal {
	.modal-dialog{
		margin: 10px auto;
	}

	}
}
@media (max-width:568px) {
	.smc__img{
		img{
			width: 100%;
		}
	}
}