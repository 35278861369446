.container-footer{
    justify-content: space-between;
    padding-bottom: 55px;
    }
footer{
    background: linear-gradient(0deg, #0D1217, #0D1217), #F7F7F7;
    padding-top: 68px;
    color:#fff;
    }
.site-footer-name{
    font-size: 14px;
    width: 195px;
    line-height: 1.2;
    margin-top: 15px;
    }
.footer-address{
    width: 277px;
    }
.footer-menu{
    li{
        line-height: 2;
        }
    a{
        color:rgba(white,.8)!important;
        }
    }
.fi__head{
    margin-bottom: 15px;
    font-family: ER;
    font-size: 20px;
    &.opacityzero{
        opacity: 0;
        }
    }
.footer-phone{
    color: #fff!important;
    font-family: RB;
    display: block;
    }
.footer-wmode{
    font-size: 14px;
    color: rgba(white,.5);
    }
.socials{
    margin-top: 25px;
    a{
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
            }
        }
    }
.footer-email{
    color: #fff!important;
    border-bottom: 1px solid #fff;
    display: inline-block;
    margin-top: 12px;
    margin-bottom: 19px;
    }
.footer-bottom{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 19px;
    border-top: 1px solid rgba(white,.3);
    p{
        margin-bottom: 0;
        font-size: 14px;
        color:#fff;
        opacity: .5;
        }
    }
.dc__name{
    font-size: 14px;
    color:#fff;
    opacity: .5;
    }

@media (max-width: 1000px) {
    .dc{
        margin-top: 10px;
    }
    .footer-bottom{
        p{
            br{
                display: none;
            }
        }
    }
    .footer__item{
        &:first-child{
            display: none;
        }
    }
}
@media (max-width: 848px) {
    .container-footer{
        padding-bottom: 30px;
    }
    footer{
        padding-top: 30px;
    }
    .footer-address{
        font-size: 14px;
        width: 240px;
    }
    .footer-email{
        font-size: 14px;
    }
    .socials{
        margin-top: 0;
    }
    .fi__head{
        display: none;
    }
    .footer__item{
        display: none;
        &:last-child{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }
}

@media (max-width: 730px) {
    .footer__item{
        &:last-child{
            flex-flow: column;
        }
    }
    .footer-phone,.footer-email{
        width: 240px;
    }
    .footer-email{
        margin-bottom: 12px;
        border-bottom: 0;
    }
    .socials{
        width: 240px;
        margin-top: 15px;
    }
    .footer-bottom{
        p{
            font-size: 12px;
        }
    }

}