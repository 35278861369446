.product-images{
    width: 644px;
    justify-content: space-between;
}
.img__thumbnales{
    width: 82px;
    margin-bottom: -10px;
}
.thumbnales__item{
    height: 64px;
    width: 82px;
    margin-bottom: 10px;
    cursor:pointer;
}
.thumbnales__item-flex{
    border:1px solid #dedede;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img__main{
    width: 532px;
    .prev{
        position: absolute;
        top: 50%;
        transform:translateY(-50%);
        left: 10px;
        z-index: 20;
        cursor:pointer;
    }
    .next{
        @extend .prev;
        left: auto;
        right: 10px;
    }
}
.slick-current{
    .thumbnales__item-flex{
        border: 2px solid #89C645;
    }
}
.img__main_item{
    border:1px solid #cecece;
    img{
        width: 100%;
    }
}
.product-view-container{
    padding-top: 15px;
    justify-content: space-between;
}
.product-information{
    width: calc(100% - 757px);
    display: flex;
    flex-flow: column;
    h1{
        margin-top: 0;
        margin-bottom: 15px;
    }
}
.atribute-flex{
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #E9EAF3;
    font-family: RR;
    color:#454545;
    font-family: RR;
    line-height: 1;
    align-items: center;
}
.destination{
    margin-top: 15px;
    font-family: RR;
}
.destination__title{
    color:#454545;
}
.dest__item{
    color:#212121;
}

/* main content*/
.product-main-info{
    margin-top: 50px;
    margin-bottom: 80px;
}
.products-nav{
    margin-bottom: 0;
    border-bottom: 1px solid #dedede;
}
.product-nav{
    // margin-top: 20px;
    .pagination-box{
        // border-top: 1px solid #D9DDE8;
        border-bottom: 1px solid #D9DDE8;
        display: flex;
        flex-wrap: wrap;
    }
}
.product-nav__item{
    cursor:pointer;
    padding-bottom: 10px;
    line-height: 1;
    margin-right: 40px;
    font-family: RR;
    position: relative;
    transition:all .3s;
    color:#212121;
    &:last-child{
        margin-right: 0;
    }
    &.active{
        color: #0038FF;
        &::before{
            content:"";
            position: absolute;
            width: 100%;
            height: 2px;
            background:#0038FF;
            left: 0;
            bottom: -1px;
        }
    }
}
.pane-item{
    // min-height: 460px;
    position: absolute;
    z-index: -1;
    opacity: 0;
    &.active{
        position: relative;
        z-index: 20;
        opacity: 1;
    }
}
.specifications__box{
    justify-content: space-between;
}
.sb__item{
    width: calc(50% - 15px);
    .atribute-flex{
        min-height: 50px;
        margin-bottom: 0;
        padding-bottom: 0;
        &:first-child{
            border-top: 1px solid #E9EAF3;
        }
    }
}
.sp__head{
    font-size: 24px;
    color:#212121;
    font-family: ER;
    margin: 10px 0 10px;
    padding-top: 15px;

}

.description-box{
    margin-top: 20px;
}
.description-box__content {
    overflow: hidden;
    height: 66px;
    position: relative;
    transition: all .3s;
    &::before{
        content:"";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 52px;
        background: url('../images/svg/tuman.svg')no-repeat 0 0;
        background-size: cover;
    }
    &.opened{
        overflow: visible;
        height: auto;
        &::before{
            opacity: 0;
            z-index: -1;
        }
    }
}
.open-content{
    display: inline-block;
    color: #0038FF;
    border-bottom: 1px solid #0038FF;
    line-height: 1;
    margin-top: 20px;
    cursor:pointer;
    &.toggled{
        margin-top: 5px;
    }
}
.description-images{
    margin-top: 30px;
    justify-content: space-between;
}
.description-images__item{
    width: calc(50% - 7.5px);
    p{
        font-size: 24px;
        font-family: ER;
        &:last-child{
            margin-bottom: 0;
        }
        img{
            width: 100%;
        }
    }
}/***/
@media (max-height: 1366px) {
    .img__main{
        width: 82.4%;
    }
    .product-images{
        width: 48.2%;
    }
    .product-information{
        width: calc(100% - 48.2% - 30px);
    }
}
@media (max-width: 1200px) {
.img__thumbnales{
    margin-top: 10px;
    margin-bottom: 0;
    margin-right: -10px;
}
.thumbnales__item{
    margin-bottom: 0;
    margin-right: 10px;
}
.img__main{
    width: 100%;
}
.img__thumbnales{
    width: 100%;
    order: 2;
}
}

@media (max-width: 991px) {
    .product-nav__item{
        margin-right: 20px;
    }
}

@media (max-width: 960px) {
    .product-information{
        width: 100%;
        margin-top: 30px;
        h1{
            font-size: 24px;
        }
    }
.product-images{
    width: 644px;
}
.product-attributes{
    width: 644px;
}
}

@media (max-width: 810px) {
    .product-nav__item{
        margin-right: 0;
        width: 100%;
        padding: 10px;
        margin-bottom: 5px;
        background: #dedede;

        &.active{
            background: #0038FF;
            color: #fff;
            &::before{
                display: none;
            }
        }
    }
}
.dimensions__box {
    padding-top: 30px;
}

@media (max-width: 674px) {
    .sb__item{
        width: 100%;
    }
    .product-images{
    width: 100%;
}
.product-attributes{
    width: 100%;
}
}