.category-child{
    justify-content: space-between;
    }
.category-child__box{
    width: 308px;
    a{
        display: block;
        }
    }
.child-children{
    width: calc(100% - 308px - 144px);
    }
.child-children{
   display: flex;
   flex-wrap: wrap;
    justify-content: space-between;
    }
.ch_wrap{
    font-size: 18px;
    line-height: 1.2;
    width: calc(50% - 19px);
    height: auto;
    }
.ch__item{
    margin-bottom: 15px;
    &:last-child{
        margin-bottom: 0;
        }
    }
.p-name{
    color:#212121;
    }
.p-desc{
    font-size: 16px;
    color:#757575;
    }
.category-head{
    padding-bottom: 50px;
    border-bottom: 1px solid #D9DDE8;
    h1{
        margin-top: 0;
        margin-bottom: 0;
        }
    }
.children-contrainer{
    // margin-top: 60px;
    }
.category-child__name{
    font-size: 30px;
    font-family: ER;
    border-bottom: 1px solid #212121;
    color: #212121;
    display: inline!important;
    }
.category-child-wrap{
    padding-top: 60px;
    padding-bottom: 97px;
    border-bottom: 1px solid #D9DDE8;
    &:last-child{
        border-bottom: 0;
        }
    }
.category-child__img{
    margin-top: 15px;
}
.cat-root-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 60px;
    margin-bottom: 102px;
}

.container-grid-children{
    width: calc(100% - 338px);
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:30px;
    &.ch-root{
        width: 100%;
        grid-template-columns: repeat(4,1fr);
    }
}
.category-sidebar{
    width: 308px;
    background-repeat: no-repeat;
    border-left: 1px solid #EDEDED;
    border-right: 1px solid #EDEDED;
}
.cgc__item{
    background: linear-gradient(0deg, #EBF1F2, #EBF1F2), #F5F3EC;
    display: flex;
    flex-flow: column;
    padding: 20px 30px;
    justify-content: space-between;
    min-height: 320px;
    transition:all .4s;
    .p-desc{
        br{
            display: none;
        }
    }
    @media (min-width: 640px) {
        &:hover{

           .cgc__image{
            img{
                transform:scale(1.1,1.1);
            }
           }
        }
    }
}
.cgc__name{
    span{
        font-size: 18px;
        color:#212121;
        font-family: ER;
        line-height: 1.1;
    }
}
.cgc__image{
    display: flex;
    justify-content: center;
    img{
        transition:all .4s;
    }

}

.adventages{
    display: grid;
    grid-template-columns: repeat(4,auto);
    grid-gap:30px;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 34px;
    width: 100%;
}
.adventages__name{
    font-size: 18px;
    font-family: ER;
    margin-top: 10px;
    margin-bottom: 5px;
}
.adventages__desc{
    color: #757575;
    font-family: RR;
    line-height: 1.2;
}
.category-short-description{
    width: 982px;
    margin-bottom: 60px;
}
.advents-container{
    margin-top: 80px;
}

.catalog-sub-menu{
    display: none;
    li{
        background: #fff!important;
        border-bottom: 1px solid #dedede;
        &:last-child{
            border-bottom: none;
        }
        a{
            font-size: 16px!important;
            padding: 15px 21px 15px 29px!important;
            pointer-events: unset!important;
            color: #454545;
            background: #f8f8f8;
        }
    }
}
.sidebar-menu{
    font-family: ER;
}
.sidebar-menu>li{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #EDEDED;
    line-height: 1.2;
    &.active{
       background: #EDEDED;
       a{
       pointer-events: none;
       }

    }
    a{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 23px 21px 23px 29px;
    width: 100%;
    font-size: 18px;
    color: #212121;
    }
}
.cpn{
    margin-bottom: 0;
    margin-top: 0;
    font-family: ER;
    font-size: 30px;
    padding: 23px 21px 23px 29px;
    border-bottom: 1px solid #EDEDED;
    border-top: 1px solid #EDEDED;
}
.mobile-parent-menu{
    width: 100%;
    display: none;
}

.industry-grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 30px;
    hr{
        grid-column: span 3;
        height: 1px;
        width: 100%;
        background: #D9DDE8;
        margin: 0;
    }
}
.industry-grid__img{
    overflow: hidden;
     // transition: all 1s;
    img{
        transition: all 1s;
    }
    @media (min-width: 640px) {
        &:hover{
            img{
                transform: scale(1.1,1.1);
            }
        }
    }
}
.industry__title{
    font-size: 18px;
    font-family: ER;
    margin-top: 20px;
    color: #212121;
}
.industry-title{
    margin-top: 0;
}
.industry-container{
    margin-top: 50px;
    margin-bottom: 90px;
}

.system-grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 30px;
    margin-bottom: 80px;
}
.system__item{
    padding: 31px 30px 37px 30px;
    border: 1px solid #D9DDE8;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F5F3EC;
}
.sys__title{
    margin-top: 20px;
    color: #212121;
    font-family: ER;
}
.system-self{
    margin-top: 60px;
}
.cat-self{
    a{
        color: #212121;
    }
}


/*STANDART*/
.container-grid-standart{
    padding-top: 30px;
    .product-list{
       display: grid;
       grid-template-columns: repeat(4,1fr);
       grid-gap:20px;
    }
    .product-box__name{
        font-size: 18px;
        color:#212121;
        font-family: EB;
        margin-top: 10px;
    }
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus{
    background: #0038FF;
    border: 1px solid #0038FF;
    color: #fff;
}
.pagination>li>a, .pagination>li>span{
    color:#0038FF;
    }
}
.properties-flex{
    justify-content: space-between;
    line-height: 1;
    margin-bottom: 5px;
    align-items: center;
    &:last-child{
        margin-bottom: 0;
    }
}
.standart-product-item{
    padding: 15px;
    box-shadow: 0 0 15px rgba(black,.1);
}
.properties__key{
font-size: 14px;
font-family: RR;
color:#757575;
}
.properties__value{
    font-size: 14px;
    font-family: RB;
    color:#494949;
}
.sp__img{
    img{
        width: 100%;
    }
}


/*////////////////////////////////////////////////////////////////////////*/
@media (max-width: 1350px) {
    .industry-grid__img{
         img{
            width: 100%;
         }
    }
}
@media (max-width: 1295px) {
    .container-grid-children{
        grid-template-columns: repeat(2,1fr);
        &.ch-root{
            grid-template-columns: repeat(3,1fr);
        }
    }
}
@media (max-width: 1250px) {
    .container-grid-standart{
    .product-list{
       grid-template-columns: repeat(3,1fr);
        }
        .product-box__name{
            font-size: 1rem;
        }
    }
}

@media (max-width: 1150px) {
    .adventages{
        grid-template-columns: repeat(2,1fr);
    }
    .adventages__item{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .adventages__img{
        margin-right: 10px;
        margin-bottom: 0;
    }
}

@media (max-width: 991px) {
    .system-self{
    margin-top: 20px;
    }
    .industry-grid{
        grid-gap: 15px;
        hr{
            display: none;
        }
    }
    .industry__title{
        font-size: 1rem;
        margin-top: 10px;
        line-height: 1;
    }
    .mobile-parent-menu{
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .mpm-link{
        align-items: center;
        span{
            margin-right: 5px;
        }
    }
    .category-sidebar{
        display: none;
        border-right: 0;
        border-left: 0;
        width: 100%;
        margin-bottom: 20px;
        .cpn{
            display: none;
        }
    }
    .container-grid-children{
        width: 100%;
        &.ch-root{
            margin-top: 20px;
        }
    }
    .cat-root-container{
        margin-top: 0;
    }
}
@media (max-width: 930px) {
    .system-grid{
        grid-template-columns:repeat(3,1fr) ;
    }
    .container-grid-children{
        &.ch-root{
            grid-template-columns: repeat(2,1fr);
        }
    }
}
@media (max-width: 920px) {
    .container-grid-standart{
    .product-list{
       grid-template-columns: repeat(2,1fr);
        }
    }
}
@media (max-width: 800px) {
    .adventages__item{
        flex-flow: column;
    }
    .adventages__img{
        margin-right: 0;
        margin-bottom: 10px;
    }
}
@media (max-width: 767px) {
     .industry-grid{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 15px;
    }
    .system-grid{
        grid-template-columns:repeat(2,1fr) ;
    }
}
@media (max-width: 675px) {
    .parent-inline{
        h2{
            text-align: center;
        }
    }
    .cat-root-container{
        margin-bottom: 30px;
    }
    .adventages{
        grid-template-columns: 1fr;
    }
    .advents__info-wrap{
        width: 297px;
        text-align: center;
    }
    .adventages__name{
    }
}
@media (max-width: 596px) {
    .container-grid-children{
        grid-template-columns: 1fr;
        &.ch-root{
            grid-template-columns: 1fr;
        }
    }
}
@media (max-width: 575px) {
    .container-grid-standart{
        .product-box__name{
            font-size: 14px;
        }
    }
    .properties__key,.properties__value{
        font-size: 11px;
    }
}
@media (max-width: 500px) {
    .system-grid{
        grid-template-columns:1fr ;
    }
    .container-grid-standart{
    .product-list{
       grid-template-columns: 1fr;
        }
        .product-box__name{
            font-size: 18px;
        }
    }
    .properties__key,.properties__value{
        font-size: 14px;
    }
    .category-head{
        padding-bottom: 20px;
    }
}

@media (max-width: 420px) {
    .industry-container{
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .industry-grid{
        grid-template-columns: 1fr;
    }
}